import React, { Component } from 'react';
// import PageNotFound from '../../assests/images/page_not.png';
import PageNotFound from "../../assets/images/page_not.png";
export default class UnAuthorizeComponent extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        // document.getElementById('homeMultiScreenDropdown').style.display = 'none';
        // document.getElementById('lblActiveComp').style.display = 'none';
    }

    render() {
        return (

            <div style={{ textAlign: 'center' }}>
                <img src={PageNotFound} style={{maxHeight: '80vh'}} alt='Loading...' />
            </div>

        )
    }
}
