import * as React from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Input } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Link } from 'react-router-dom';





export const UploadDataComp = (props) => {

    return (
        <Box>

            <Box>
                <Grid container>
                    <Grid md={3}>
                        <Box m={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Process</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.mystate.ddlProcessId}
                                    label="Process"
                                    onChange={(e) => props.HandleInputs(e, 'ddlProcessId')}
                                >
                                    {
                                        props.processList != undefined && props.processList.length > 0 ?
                                            props.processList.map((item, i) =>
                                                <MenuItem value={item._id}>{item.process_name}</MenuItem>
                                            )
                                            :
                                            null
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid md={6}>
                      <Box m={2}>
                      <Link to="https://app.pinclick.com/images/sample.xlsx" target="_blank" download>
                      <Button
                      component="label"
                      role={undefined}
                      variant="contained"

                      tabIndex={-1}
                      startIcon={<CloudDownloadIcon />}
                    >
                      Download Sample File Here
                    </Button>
                      </Link>
                    
                      </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box mt={2}>
            {
                props.mystate.getPoolDataCount == false && props.mystate.getProcessList == false ?
                <Typography variant='h6'>Data Available : {props.mystate.poolDataCount}</Typography>
                :
                ''
            }
            </Box>

            <Box>
                <Grid container mt={5}>
                    <Grid md={4}></Grid>
                    <Grid md={4}>

                        <Typography mb={2} style={{color:'red'}}>Max 1000 rows allowed</Typography>

                        <Input type='file' value={props.mystate.txtFileName} id='txtFileName' name='txtFileName' onChange={(e) => props.HandleInputs(e, 'txtFileName')}>

                        </Input>
                        <Box mt={2}>
                            <Button disabled={props.mystate.uploadData == true ? true : false} variant="contained" onClick={() => props.UploadData()}>Upload Data</Button>
                        </Box>

                        {
                            props.mystate.uploadData == true ?

                            <Box mt={2}>
                            <LinearProgress />
                            </Box>

                            :

                            <Box></Box>
                        }
                       

                    </Grid>
                </Grid>
            </Box>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={props.mystate.showAlert} autoHideDuration={2000} onClose={() => { props.handleCloseAlert() }}>
                <Alert
                    onClose={() => { props.handleCloseAlert() }}
                    severity={props.mystate.alertType}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {props.mystate.alertMsg}
                </Alert>
            </Snackbar>
        </Box>


    );
}