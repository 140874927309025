import { Box } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { GetPatchData, GetCallFilterCount, GetCallLogsCount, GetProcessDetails, GetProcessList } from '../../models/process';
import { pnkstore } from '../../stores/pnk-store';
import { useUserStore } from '../../stores/user-store';
import { useNavigate, useLocation } from 'react-router-dom';
import { PatchDashboardComp } from './dashboard';


export const PatchDashboard = () => {
    const userData = useUserStore;
    //Get Patch Data
    const [getPatchApi, setPatchApi] = useState(false);
    const [patchData, setPatchData] = useState(true);
    const search_data = useRef("");

    const [getProcessList, setGetProcessList] = useState(true);
    const [processList, setProcessList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [ddlProcessId, setddlProcessId] = useState('');
    const [ddlAgentName, setddlAgentName] = useState('All');
    const [alertBox, setAlertBox] = useState(false);
    const [userName, setUserName] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [txtStartDate, settxtStartDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' '));
    const [txtEndDate, settxtEndDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' '));
    const navigate = useNavigate();


    const state = {
        ddlProcessId: ddlProcessId,
        ddlAgentName: ddlAgentName,
        getProcessList: getProcessList,
        userList: userList,
        getPatchApi: getPatchApi,
        alertBox: alertBox,
        userName: userName,
        loginUserProfile: userData.getState().profile,
        reportingAgents: userData.getState().reportingAgents,
        txtStartDate: txtStartDate,
        txtEndDate: txtEndDate,
        showAlert: showAlert,
        alertMsg: alertMsg
    }

    useEffect(() => {

    }, []);


    const NavigateTo = (route) => {
        navigate('/' + route, { state: { processId: ddlProcessId } });
    }


    const HandleInputs = (e, name) => {
        let value = '';

        if (name == 'txtStartDate' || name == 'txtEndDate') {
            value = e.$d;
        }
        else {
            value = e.target.value;
        }

        if (name == 'ddlProcessId') {
            let index = processList.findIndex((item) => value == item.process_name);
            if (index > -1) {
                setUserList((processList[index].process_members_details).sort());
            }
            else {
                setUserList([]);
            }
            setddlProcessId(value);
            search_data.process_name = value;
            search_data.ddlOwnerName = 'All';
            setddlAgentName('All');
            setPatchApi(true);
        }
        else if (name == 'ddlAgentName') {
            setddlAgentName(value);
            search_data.ddlOwnerName = value;
            setPatchApi(true);
        }
        else if (name == 'txtStartDate') {
            let date = new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ')
            settxtStartDate(date);
        }

        else if (name == 'txtEndDate') {
            let date = new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ')
            settxtEndDate(date);
        }

    }

    const HandleCloseAlert = (type) => {
        if (type == 'No') {
            setAlertBox(false);
        }
        else {
        }
    }

    const HandleGetPatchDataSuccessResponse = (res) => {
        if (res != false && res != 'error') {
            let patchListData = res.data.patchsummaryresponse.rows;
            let arr = [];
            patchListData.forEach((element, i) => {
                if (arr.length == 0) {
                    arr.push({ "user_name": element.owner_name, "patch_count": 1, "progress": element.stage == "In Progress" ? 1 : 0, "s_sv": element.stage == "S-SV" ? 1 : 0, "sv": element.stage == "SV" ? 1 : 0, "dead": element.stage == "Dead" ? 1 : 0 });
                }
                else {
                    let typeIndex = arr.findIndex(item => element.owner_name == item.user_name);
                    if (typeIndex !== -1) {
                        arr[typeIndex].patch_count = arr[typeIndex].patch_count + 1;
                        if (element.stage == "In Progress")
                            arr[typeIndex].progress = arr[typeIndex].progress + 1;
                        else if(element.stage == "S-SV"){
                            arr[typeIndex].s_sv = arr[typeIndex].s_sv + 1;
                        }
                        else if(element.stage == "SV"){
                            arr[typeIndex].sv = arr[typeIndex].sv + 1;
                        }
                        else if(element.stage == "Dead"){
                            arr[typeIndex].dead = arr[typeIndex].dead + 1;
                        }
                    }
                    else {
                        arr.push({ "user_name": element.owner_name, "patch_count": 1, "progress": element.stage == "In Progress" ? 1 : 0, "s_sv": element.stage == "S-SV" ? 1 : 0, "sv": element.stage == "SV" ? 1 : 0, "dead": element.stage == "Dead" ? 1 : 0 });
                    }
                }
                if (patchListData.length == i + 1) {
                    setPatchData(arr);
                }
            });
        }
        else {
            setPatchData([]);
            console.log(pnkstore.error.prettyMessage);
        }
        setPatchApi(false);
    }

    const HandleGetProcessListSuccessResponse = (res) => {
        if (res != false && res != 'error') {
            let processListData = res.data.processlist.rows
            setProcessList(processListData);
            setddlProcessId(processListData[1] != undefined ? processListData[1].process_name : processListData[0].process_name);
            setUserList(processListData[1] != undefined ? (processListData[1].process_members_details).sort() : (processListData[0].process_members_details).sort());
            //  setGetProcessDetails(true);
            //Call Patch API
            search_data.process_name = processListData[1] != undefined ? processListData[1].process_name : processListData[0].process_name;
            search_data.txtStartDate = txtStartDate;
            search_data.txtEndDate = txtEndDate;
            setPatchApi(true);
        }
        else {
            setProcessList([]);
        }
        setGetProcessList(false);
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
        setAlertMsg('');
    }

    const CustomSearch = () => {

        if (txtStartDate == '' && txtEndDate != '') {
            // alert('WRONG');
            setShowAlert(true);
            setAlertMsg('Select Correct Date Range');
            return false;
        }

        if (txtStartDate != '' && txtEndDate != '') {
            if (txtEndDate < txtStartDate) {
                setShowAlert(true);
                setAlertMsg('Select Correct Date Range');
                return false;
            }
        }


        search_data.txtStartDate = txtStartDate;
        search_data.txtEndDate = txtEndDate;
        setPatchApi(true);
    }

    const Reload = () => {

        let startDate = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ');
        let endDate = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ');


        settxtStartDate(startDate);
        settxtEndDate(endDate);

        search_data.txtStartDate = startDate;
        search_data.txtEndDate = endDate;
        setPatchApi(true);
    }


    return (
        <Box>
            <PatchDashboardComp processList={processList} mystate={state} HandleInputs={HandleInputs} HandleCloseAlert={HandleCloseAlert} NavigateTo={NavigateTo}
                CustomSearch={CustomSearch} Reload={Reload} handleCloseAlert={handleCloseAlert}
                patchData={patchData} />
            {((getProcessList === true) ? GetProcessList(pnkstore, userData.getState().profile, userData.getState().username, userData.getState().orgId, userData.getState().token, HandleGetProcessListSuccessResponse) : "")}
            {((getPatchApi === true) ? GetPatchData(pnkstore, userData.getState().profile, userData.getState().username, search_data, 0, 0, userData.getState().token, HandleGetPatchDataSuccessResponse) : "")}
        </Box>
    )
}