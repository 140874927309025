//import { PnkAjax } from '../api/pnk-ajax';
import { PnkAjax } from '../pnk-react/ajax/pnk-ajax';

export function LoginUser(store, data, SuccessCallBackFunc) {
    console.log(data);
    let loader = { isLoader: false, containecccrId: 'testpnk', size: 'small' };
    return PnkAjax(store).Post('login/json').ExtraData(data).AttachLoader(loader).OnSuccess(SuccessCallBackFunc).Do();
}

export function LoginByNumber(store, userData, SuccessCallBackFunc) {
    let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Post('call/loginbynumber/json').ExtraData(userData).AttachLoader(loader).OnSuccess(SuccessCallBackFunc).Do();
}

