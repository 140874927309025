import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LeadHistoryComp } from './leadhistory';
import { pnkstore } from '../../stores/pnk-store';
import { useUserStore } from '../../stores/user-store';
import { useParams } from 'react-router-dom';
import { GetLeadHistoryByStorageId } from '../../models/process';
import { useNavigate } from 'react-router-dom';


export const LeadHistory = (props) => {
    const userData = useUserStore;
    const [getLeadHistory, setGetLeadHistory] = useState(false);
    const params = useParams();
    const [custName, setCustName] = useState(true);
    const [custMobile, setCustMobile] = useState(true);
    const [storageId, setStorageId] = useState(true);
    const [leadHistoryData, setLeadHistoryData] = useState([]);
    const navigate = useNavigate();
    


    const state = {
        getLeadHistory:getLeadHistory,
        leadHistoryData:leadHistoryData,
    }

    useEffect(() => {
        let hideId = params.storage_id;

        let hideData = window.atob(hideId);

        let storage_id = hideData.slice(0, hideData.indexOf('-'));
        let cust_mobile = hideData.slice(hideData.indexOf('-')+1, hideData.length);

        console.log(storage_id);
        console.log(cust_mobile);

        setCustMobile(cust_mobile);
        setStorageId(storage_id);
       

        
        setGetLeadHistory(true);

        //console.log(hideData.storageId); 


       
    }, []);

    const NavigateTo = (route) => {
        navigate('/' + route, { replace: true });
    }


    const HandleGetLeadHistorySuccessResponse = (res) =>{
        if(res != false && res != 'error'){
            console.log(res.data.leadhistorydataresponse.rows);
           setLeadHistoryData(res.data.leadhistorydataresponse.rows);
        }
        else{
            setLeadHistoryData([]);
        }
        setGetLeadHistory(false)
    }
    

    return (
        <Box>
            <LeadHistoryComp mystate={state} NavigateTo={NavigateTo} />
            {((getLeadHistory === true) ? GetLeadHistoryByStorageId(pnkstore, userData.getState().profile, userData.getState().username, userData.getState().orgId, storageId, custMobile, userData.getState().token, HandleGetLeadHistorySuccessResponse) : "")}
        </Box>
    )
}