import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Grid, Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export const UsersComp = (props) => {


    return (
        <Box>
            <Box>
                <Grid container>
                    <Grid md={3}>
                        <Box>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Process</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.mystate.ddlProcessId}
                                    label="Process"
                                    onChange={(e) => props.HandleInputs(e, 'ddlProcessId')}
                                >
                                    {
                                        props.processList != undefined && props.processList.length > 0 ?
                                            props.processList.map((item, i) =>
                                                <MenuItem value={item._id}>{item.process_name}</MenuItem>
                                            )
                                            :
                                            null
                                    }
                                </Select>
                            </FormControl>
                        </Box>

                    </Grid>
                    <Grid md={3}>
                    </Grid>
                    <Grid md={3}>
                    </Grid>
                    <Grid md={3}>
                        <Box textAlign={'end'}>
                            <Button variant="outlined" startIcon={<AddIcon />} onClick={() => props.NavigateTo('user')}>
                                Add User
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box style={{ marginTop: '20px' }}>
                {
                    props.mystate.getProcessList == false ?
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 400 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                key={'SrNo'}
                                                align={'left'}
                                                style={{ minWidth: 70 }}
                                                className='table-head-cell'
                                            >
                                                {'SrNo'}
                                            </TableCell>
                                            <TableCell
                                                key={'Name'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Name'}
                                            </TableCell>
                                            <TableCell
                                                key={'Role'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Role'}
                                            </TableCell>

                                            <TableCell
                                                key={'Reporting'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Reporting'}
                                            </TableCell>
                                            <TableCell
                                                key={'Added On'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Added On'}
                                            </TableCell>
                                            <TableCell
                                                key={'Details'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {''}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            console.log(props.mystate.userList)
                                        }
                                        {props.mystate.userList != undefined && props.mystate.userList.length > 0 ?
                                            props.mystate.userList.map((item, i) => (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={item._id}>
                                                    <TableCell key={i + 1} align={'left'}>
                                                        {i + 1}
                                                    </TableCell>
                                                    <TableCell key={item.name + i} align={'left'}>
                                                        {item.name}
                                                    </TableCell>
                                                    <TableCell key={item.role + i} align={'left'}>
                                                        <span>{item.role}</span>
                                                    </TableCell>
                                                    <TableCell key={i} align={'left'}>
                                                        {(item.reports).toString()}
                                                    </TableCell>

                                                    <TableCell key={item.added_on + i} align={'left'}>
                                                        {item.added_on}
                                                    </TableCell>
                                                    {
                                                        props.mystate.loginUserProfile == 'Super-Admin' ?
                                                            <TableCell key={item.cust_added_on + i} align={'left'}>
                                                                {item.role != 'Super-Admin' ?
                                                                    <Button><EditIcon onClick={() => props.GetUserDetails(item.name)}></EditIcon></Button>
                                                                    : null}
                                                                {
                                                                    item.role == 'Agent' || item.role == 'Admin' ?
                                                                        <Button onClick={() => props.DeleteUser(item.name)}><DeleteIcon></DeleteIcon></Button>
                                                                        :
                                                                        ''
                                                                }

                                                            </TableCell>
                                                            :
                                                            props.mystate.loginUserProfile == 'Admin' ?
                                                                item.role == 'Agent' ?
                                                                    <TableCell key={item.cust_added_on + i} align={'left'}>
                                                                        <Button><EditIcon onClick={() => props.GetUserDetails(item.name)}></EditIcon></Button>
                                                                        <Button onClick={() => props.DeleteUser(item.name)}><DeleteIcon></DeleteIcon></Button>
                                                                    </TableCell>
                                                                    :
                                                                    ''
                                                                :
                                                                ''
                                                    }

                                                </TableRow>
                                            ))
                                            : ''
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Paper>
                        :

                        <Box>
                            <Skeleton height={75} />
                            <Skeleton animation="wave" variant="rectangular" height={250} />
                            <Skeleton height={75} />
                        </Box>
                }

            </Box>


            <Dialog
                open={props.mystate.alertBox}
                onClose={props.HandleCloseAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete User"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {'Do you want to remove ' + props.mystate.userName + ' permanently'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.HandleCloseAlert('No')}>Disagree</Button>
                    <Button onClick={() => props.HandleCloseAlert('Yes')} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>


    );
}