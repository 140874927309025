import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Box, Grid, Button, Card, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Skeleton from '@mui/material/Skeleton';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import Badge from '@mui/material/Badge';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';


export const LeadDetailsComp = (props) => {


  return (
    <Box>
      <Box className="form-head" p={2}>
        <Grid container>
          <Grid md={3}>
            <Box>
              <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => props.NavigateTo('patch-list')}>
                Back
              </Button>
            </Box>
          </Grid>
          <Grid md={3}>

          </Grid>
          <Grid md={3}>

          </Grid>
          <Grid md={3}>
            {
              props.mystate.userData.getState().profile == 'Super-Admin' || props.mystate.userData.getState().profile == 'Admin' || (props.mystate.userData.getState().reportingAgents != '' && props.mystate.userData.getState().reportingAgents.length > 0) ?
                <Box textAlign={'right'}>
                  {
                    props.mystate.screenType == 'details' ?
                      <Button variant="outlined" onClick={() => props.ToggleScreen('comments')}>Show Comments</Button>
                      :
                      <Button variant="outlined" onClick={() => props.ToggleScreen('details')}>Show Details</Button>
                  }
                </Box>
                :
                <Box></Box>
            }

          </Grid>
        </Grid>
      </Box>

      {
        props.mystate.screenType == 'details' ?
          <Box style={{ marginTop: '20px' }}>
            <Grid container>
              <Grid md={3}>
                <Box p={2}>
                  <TextField className="text-input" id="txtLeadUserName" name='txtLeadUserName' disabled={true} onChange={(e) => props.HandleInputs(e, 'txtLeadUserName')} value={props.mystate.txtLeadUserName} label="Customer Name" variant="outlined" />
                </Box>
              </Grid>
              <Grid md={3}>
                <Box p={2}>
                  <TextField className="text-input" id="txtLeadUserContactNo" name='txtLeadUserContactNo' disabled={true} onChange={(e) => props.HandleInputs(e, 'txtLeadUserContactNo')} value={props.mystate.txtLeadUserContactNo} label="Customer Number" variant="outlined" />
                </Box>
              </Grid>
              <Grid md={3}>
                <Box p={2}>
                  <TextField className="text-input" id="txtLeadCity" name='txtLeadCity' disabled={true} onChange={(e) => props.HandleInputs(e, 'txtLeadCity')} value={props.mystate.txtLeadCity} label="Lead City" variant="outlined" />
                </Box>
              </Grid>
              <Grid md={3}>
                <Box p={2}>
                  <TextField className="text-input" id="txtLeadProcessName" name='txtLeadProcessName' disabled={true} onChange={(e) => props.HandleInputs(e, 'txtLeadProcessName')} value={props.mystate.txtLeadProcessName} label="Process Name" variant="outlined" />
                </Box>
              </Grid>

              <Grid md={3}>
                <Box p={2}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Lead Project</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={props.mystate.ddlLeadProject}
                      label="User Type"
                      error={props.mystate.ddlLeadProjectErr == '' ? false : true}
                      // helperText={props.mystate.ddlLeadProjectErr}
                      onChange={(e) => props.HandleInputs(e, 'ddlLeadProject')}
                    >
                      {
                        props.mystate.processListData != undefined && props.mystate.processListData.length > 0 ?
                          props.mystate.processListData.map((item, i) =>
                            <MenuItem value={item.process_name}>{item.process_name}</MenuItem>
                          )
                          :
                          null
                      }
                    </Select>
                    <FormHelperText style={{ color: '#d32f2f' }}>{props.mystate.ddlLeadProjectErr}</FormHelperText>
                  </FormControl>
                </Box>
              </Grid>



              <Grid md={3}>
                <Box p={2}>
                  <TextField className="text-input" id="txtLeadOwnerName" name='txtLeadOwnerName' disabled={true} onChange={(e) => props.HandleInputs(e, 'txtLeadOwnerName')} value={props.mystate.txtLeadOwnerName} label="Lead Owner" variant="outlined" />
                </Box>
              </Grid>

              <Grid md={3}>
                <Box p={2}>
                  <TextField className="text-input" id="txtLeadPatchDateTime" name='txtLeadPatchDateTime' disabled={true} onChange={(e) => props.HandleInputs(e, 'txtLeadPatchDateTime')} value={props.mystate.txtLeadPatchDateTime} label="Lead Patch Time" variant="outlined" />
                </Box>
              </Grid>



              <Grid md={3}>
                <Box p={2}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Lead Stage</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={props.mystate.ddlLeadStage}
                      label="User Type"
                      error={props.mystate.ddlLeadStageErr == '' ? false : true}
                      // helperText={props.mystate.ddlLeadProjectErr}
                      onChange={(e) => props.HandleInputs(e, 'ddlLeadStage')}
                    >

                      <MenuItem key={"ddlLeadStage1"} color='#B5B3B0' value={''}>{'Select Lead Stage'}</MenuItem>
                      <MenuItem key={"ddlLeadStage2"} value={'S-SV'}>{'S-SV'}</MenuItem>
                      <MenuItem key={"ddlLeadStage3"} value={'SV'}>{'SV'}</MenuItem>
                      <MenuItem key={"ddlLeadStage4"} value={'Dead'}>{'Dead'}</MenuItem>
                      <MenuItem key={"ddlLeadStage5"} value={'In Progress'}>{'In Progress'}</MenuItem>

                    </Select>
                    <FormHelperText style={{ color: '#d32f2f' }}>{props.mystate.ddlLeadStageErr}</FormHelperText>
                  </FormControl>
                </Box>
              </Grid>

              {
                props.mystate.ddlLeadStage == 'S-SV' ?
                  <Grid md={3}>
                    <Box p={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker slotProps={{textField:{error:props.mystate.txtSSVDateErr != '' ? true : false, helperText:props.mystate.txtSSVDateErr}}} minDate={dayjs()} onChange={(e) => props.HandleInputs(e, 'txtSSVDate')} label="S-SV Date" value={dayjs(props.mystate.txtSSVDate)} />
                        </DemoContainer>
                      </LocalizationProvider>
                     
                    </Box>
                  </Grid>
                  :
                  ''
              }


              {
                props.mystate.ddlLeadStage == 'SV' ?
                  <Grid md={3}>
                    <Box p={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker slotProps={{textField:{error:props.mystate.txtSVDateErr != '' ? true : false, helperText:props.mystate.txtSVDateErr}}} minDate={dayjs()} onChange={(e) => props.HandleInputs(e, 'txtSVDate')} label="SV Date" value={dayjs(props.mystate.txtSVDate)} />
                        </DemoContainer>
                      </LocalizationProvider>
                    
                    </Box>
                  </Grid>
                  :
                  ''
              }

              {
                props.mystate.ddlLeadStage == 'Dead' ?
                  <Grid md={3}>
                    <Box p={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker slotProps={{textField:{error:props.mystate.txtDeadDateErr != '' ? true : false, helperText:props.mystate.txtDeadDateErr}}} minDate={dayjs()} onChange={(e) => props.HandleInputs(e, 'txtDeadDate')} label="Dead Date" value={dayjs(props.mystate.txtDeadDate)} />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  :
                  ''
              }

              {
                props.mystate.ddlLeadStage == 'Dead' ?
                  <Grid md={3}>
                    <Box p={2}>
                      <TextField rows={3} multiline className="text-input" id="txtDeadReason" name='txtDeadReason' error={props.mystate.txtDeadReasonErr == '' ? false : true} helperText={props.mystate.txtDeadReasonErr} onChange={(e) => props.HandleInputs(e, 'txtDeadReason')} value={props.mystate.txtDeadReason} label="Dead Reason" variant="outlined" />
                    </Box>
                  </Grid>
                  :
                  ''
              }


              <Grid md={3}>
                <Box p={2}>
                  <TextField rows={3} multiline className="text-input" id="txtLeadDescription" name='txtLeadDescription' disabled={true} onChange={(e) => props.HandleInputs(e, 'txtLeadDescription')} value={props.mystate.txtLeadDescription} label="Lead Description" variant="outlined" />
                </Box>
              </Grid>

              <Grid md={3}>
                <Box p={2}>
                  <TextField rows={3} multiline error={props.mystate.txtLeadCommentErr == '' ? false : true} helperText={props.mystate.txtLeadCommentErr} className="text-input" id="txtLeadComment" name='txtLeadComment' onChange={(e) => props.HandleInputs(e, 'txtLeadComment')} value={props.mystate.txtLeadComment} label="Lead Comments" variant="outlined" />
                </Box>
              </Grid>







              <Grid md={12}>
                <Box mt={2} textAlign={'center'}>
                  {
                    props.mystate.updateLead == false ?
                      <Button variant="contained" onClick={() => props.UpdateLead()}>Update Lead</Button>
                      :
                      <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                        className=''
                      >
                        Update User
                      </LoadingButton>
                  }
                </Box>
              </Grid>
            </Grid>

          </Box>
          :
          <Box p={2}>
            <Grid container>
              <Grid sm={12}>
                <Grid container>
                  <Grid sm={6} md={3}>
                    <Typography className='comment-head' variant='h6'>Client Name : {props.mystate.leadDetails.cust_name}</Typography>
                  </Grid>
                  <Grid sm={6} md={3}>
                    <Typography className='comment-head' variant='h6'>Client Number : {props.mystate.leadDetails.cust_mobile}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid sm={6}>
                {
                  props.mystate.commentlist == false ?
                    <Box mt={2}>
                      <Card variant="outlined">
                        <Box className="history-tree">
                          {
                            props.mystate.tlCommentsList.length > 0 ?
                              <Timeline sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                  flex: 0.3,
                                },
                              }}>
                                {
                                  props.mystate.tlCommentsList.length > 0 ?

                                    props.mystate.tlCommentsList.map((item, i) =>
                                      <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                          {item.comment_date}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                          <TimelineDot />
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent >
                                          <Paper elevation={3}>
                                            <Card>
                                              <CardContent>
                                                <Grid container>
                                                  <Grid md={3}>

                                                    <Typography className='prox-reg' mt={2} ml={1}>{item.commented_by}</Typography>
                                                  </Grid>
                                                  <Grid md={1}></Grid>
                                                  <Grid md={8}>
                                                    <Box>
                                                      <Box className="lead-history-box">
                                                        <Typography className='prox-reg gray font-sm'>{item.comments}</Typography>
                                                      </Box>

                                                    </Box>

                                                  </Grid>
                                                </Grid>
                                              </CardContent>
                                            </Card>
                                          </Paper>
                                        </TimelineContent>
                                      </TimelineItem>
                                    )


                                    :
                                    ''
                                }
                              </Timeline>
                              :
                              <Typography p={2}>No Comments</Typography>
                          }
                        </Box>
                      </Card>
                    </Box>
                    :
                    <Box mt={2}>
                      <Skeleton animation="wave" variant="rectangular" height={320} />
                    </Box>
                }




              </Grid>

              <Grid sm={1}></Grid>

              <Grid sm={5}>
                <Box mt={2}>
                  <Box>
                    <Typography className='comment-head' variant='h5'>Drop Your Comment Here</Typography>
                  </Box>
                  <Grid container>
                    <Grid sm={12}>
                      <Box pt={2}>
                        <TextField rows={4} multiline error={props.mystate.txtTLCommentErr == '' ? false : true} helperText={props.mystate.txtTLCommentErr} className="text-input" id="txtTLComment" name='txtTLComment' onChange={(e) => props.HandleInputs(e, 'txtTLComment')} value={props.mystate.txtTLComment} label="Add New Comment" variant="outlined" />
                      </Box>
                    </Grid>
                    <Grid sm={12}>
                      <Box pt={2}>
                        {
                          props.mystate.addComment == false ?
                            <Button variant="contained" onClick={() => props.AddNewComment()}>Submit</Button>
                            :
                            <LoadingButton
                              loading
                              loadingPosition="start"
                              startIcon={<SaveIcon />}
                              variant="outlined"
                              className=''
                            >
                              Submit
                            </LoadingButton>
                        }
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
      }

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={props.mystate.showAlert} autoHideDuration={2000} onClose={() => { props.handleCloseAlert() }}>
        <Alert
          onClose={() => { props.handleCloseAlert() }}
          severity={props.mystate.alertType}
          variant="filled"
          sx={{ width: '100%' }}

        >
          {props.mystate.alertMsg}
        </Alert>
      </Snackbar>
    </Box>


  );
}