import { Routes, Route } from 'react-router-dom';
import Login from '../components/Login'
import { SinglePageLayout } from '../common/layout/singlepage';
import { Dashboard } from '../components/Dashboard';
import { PatchDashboard } from '../components/Dashboard-patch';
import { DashboardCall } from '../components/Dashboard-call';
import { Leads } from '../components/Leads';
import { LeadHistory } from '../components/LeadHistory';
import { UploadData } from '../components/UploadData';
import { Users } from '../components/Users';
import { UserDetails } from '../components/UserDetails';
import { PatchList } from '../components/PatchList';
import { CallLogs } from '../components/CalleyoLogs';
import { useUserStore } from '../stores/user-store';
import UnAuthorizeComponent from '../components/UnAuthorizeComp/templates';
import { DataRechurn } from '../components/DataRechurn';
import { LeadDetails } from '../components/LeadDetails';

const Root = () => {
    const userData = useUserStore;
    // console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
    // console.log(JSON.stringify(userData));
    // console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
    // console.log(JSON.stringify(userData.getState()));
    // console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
    return (

        <Routes>
            {(userData.getState().profile).includes('Admin') ?
                <Route path='/call-logs' element={<SinglePageLayout component={CallLogs} />} />
                :
                <Route path='/call-logs' element={<SinglePageLayout component={UnAuthorizeComponent} />} />
            }

            <Route path='/summary' element={<SinglePageLayout component={Dashboard} />} />
            <Route path='/call-summary' element={<SinglePageLayout component={DashboardCall} />} />
            <Route path='/dashboard-patch' element={<SinglePageLayout component={PatchDashboard} />} />
            <Route path='/patch-list' element={<SinglePageLayout component={PatchList} />} />
            {(userData.getState().profile).includes('Admin') ?
                <Route path='/users' element={<SinglePageLayout component={Users} />} />
                :
                <Route path='/users' element={<SinglePageLayout component={UnAuthorizeComponent} />} />
            }
            {(userData.getState().profile).includes('Admin') ?
                <Route path='/user/:user_name?' element={<SinglePageLayout component={UserDetails} />}>
                </Route>
                : <Route path='/user/:user_name?' element={<SinglePageLayout component={UnAuthorizeComponent} />} />}

            {(userData.getState().profile).includes('Admin') ?
                <Route path='/leads' element={<SinglePageLayout component={Leads} />} />
                :
                <Route path='/leads' element={<SinglePageLayout component={UnAuthorizeComponent} />} />
            }

            {(userData.getState().profile).includes('Admin') ?
                <Route path='/leadhistory/:storage_id' element={<SinglePageLayout component={LeadHistory} />} />
                :
                <Route path='/leadhistory/:storage_id' element={<SinglePageLayout component={UnAuthorizeComponent} />} />
            }

            <Route path='/leaddetails/:lead_id' element={<SinglePageLayout component={LeadDetails} />} />

            {(userData.getState().profile).includes('Admin') ?
                <Route path='/uploaddata' element={<SinglePageLayout component={UploadData} />}>
                </Route>
                : <Route path='/uploaddata' element={<SinglePageLayout component={UnAuthorizeComponent} />} />}

            {(userData.getState().profile).includes('Admin') ?
                <Route path='/data-rechurn' element={<SinglePageLayout component={DataRechurn} />}>
                </Route>
                : <Route path='/data-rechurn' element={<SinglePageLayout component={UnAuthorizeComponent} />} />}


            <Route path='/login' element={<SinglePageLayout component={Login} />}>
            </Route>
            <Route path='/' element={<SinglePageLayout component={''} />}>
            </Route>
        </Routes>
    );
}

export default Root;