//import './App.css';
import Root from './routes/routes';
import './assets/css/style.css';
function App() {
  return (
    <div className="App">
         <Root/>
    </div>
  );
}

export default App;
