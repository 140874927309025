import { Box } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { PatchListComp } from './patchlist';
import { GetLeadsFromStorage, GetPatchData, GetProcessList } from '../../models/process';
import { pnkstore } from '../../stores/pnk-store';
import { useUserStore } from '../../stores/user-store';
import { useNavigate } from 'react-router-dom';

export const PatchList = () => {
    const userData = useUserStore;
    const search_data = useRef("");
    const [getProcessList, setGetProcessList] = useState(true);
    const [processList, setProcessList] = useState([]);
    const [getPatchApi, setPatchApi] = useState(false);
    const [patchData, setPatchData] = useState([]);
    const [userList, setUserList] = useState([]);
    const [setNo, setSetNo] = useState(1);
    const [perSet, setPerSet] = useState(50);
    const [pageLength, setPageLength] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [ddlProcessId, setddlProcessId] = useState('');
    const [ddlAgentName, setddlAgentName] = useState('All');
    const [leadObj, setLeadObj] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const navigate = useNavigate();
    const [txtStartDate, settxtStartDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' '));
    const [txtEndDate, settxtEndDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' '));


    const state = {
        ddlProcessId: ddlProcessId,
        ddlAgentName: ddlAgentName,
        getPatchApi: getPatchApi,
        getProcessList: getProcessList,
        patchData: patchData,
        totalRecords: totalRecords,
        perSet: perSet,
        setNo: setNo,
        userList: userList,
        showAlert: showAlert,
        alertMsg: alertMsg,
        txtStartDate: txtStartDate,
        txtEndDate: txtEndDate,
        loginUserProfile: userData.getState().profile,
        reportingAgents: userData.getState().reportingAgents,
    }



    useEffect(() => {
       // alert('I AM HERE');
        //  setGetProcessList(true)
    }, []);


    const handleChangePage = (event, page) => {
        let set_no = setNo;
        if (event.currentTarget.title == 'Go to next page') {
            set_no = set_no + 1;
        }
        else {
            set_no = set_no - 1;
        }
        setSetNo(set_no);

        setPatchApi(true);
    };

    const handleChangeRowsPerPage = (event) => {

        let value = event.target.value;

        console.log(event.target.value);
        setPerSet(value);
        setSetNo(1);

        // search_data.per_set = value;
        // search_data.set_no = 1;

        setPatchApi(true);

    };

    const HandleInputs = (e, name) => {
        let value = '';

        if (name == 'txtStartDate' || name == 'txtEndDate') {
            value = e.$d;
        }
        else {
            value = e.target.value;
        }

        if (name == 'ddlProcessId') {
            let index = processList.findIndex((item) => value == item.process_name);
            if (index > -1) {
                setUserList((processList[index].process_members_details).sort());
            }
            else {
                setUserList([]);
            }
            setddlProcessId(value);
            search_data.process_name = value;
            search_data.ddlOwnerName = 'All';
            setPatchApi(true);
            setddlAgentName('All');
        }
        else if (name == 'ddlAgentName') {
            setddlAgentName(value);
            search_data.ddlOwnerName = value;
            setPatchApi(true);
        }
        else if (name == 'txtStartDate') {
            let date = new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ')
            settxtStartDate(date);
        }

        else if (name == 'txtEndDate') {
            let date = new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ')
            settxtEndDate(date);
        }
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
        setAlertMsg('');
    }

    const CustomSearch = () => {

        if (txtStartDate == '' && txtEndDate != '') {
            // alert('WRONG');
            setShowAlert(true);
            setAlertMsg('Select Correct Date Range');
            return false;
        }

        if (txtStartDate != '' && txtEndDate != '') {
            if (txtEndDate < txtStartDate) {
                setShowAlert(true);
                setAlertMsg('Select Correct Date Range');
                return false;
            }
        }


        search_data.txtStartDate = txtStartDate;
        search_data.txtEndDate = txtEndDate;
        setPatchApi(true);
    }

    const Reload = () => {

        let startDate = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ');
        let endDate = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ');


        settxtStartDate(startDate);
        settxtEndDate(endDate);
        setSetNo(1);
        setPerSet(50);

        search_data.txtStartDate = startDate;
        search_data.txtEndDate = endDate;
        setPatchApi(true);
    }



    const HandleGetProcessListSuccessResponse = (res) => {
        if (res != false && res != 'error') {
            let processListData = res.data.processlist.rows;
            
            setProcessList(processListData);
            setddlProcessId(processListData[1] != undefined ? processListData[1].process_name : processListData[0].process_name);
            setUserList(processListData[1] != undefined ? (processListData[1].process_members_details).sort() : (processListData[0].process_members_details).sort());
            search_data.process_name = processListData[1] != undefined ? processListData[1].process_name : processListData[0].process_name;
            search_data.txtStartDate = txtStartDate;
            search_data.txtEndDate = txtEndDate;
            // search_data.per_set = perSet;
            // search_data.set_no = setNo;
            setPatchApi(true);
        }
        else {
            setProcessList([]);
        }
        setGetProcessList(false);
    }

    const HandleGetPatchDataSuccessResponse = (res) => {
        if (res != false && res != 'error') {
            let patchListData = res.data.patchsummaryresponse.rows;
            setTotalRecords(res.data.patchsummaryresponse.metarow.totalRecordsInDb);
            setPatchData(patchListData);
        }
        else {
            setTotalRecords(0);
            setPatchData([]);
            console.log(pnkstore.error.prettyMessage);
        }
        setPatchApi(false);
    }

    const GetLeadDetails = (leadId, lead) => {
        navigate('/leaddetails/' + leadId, { replace: true, state: {leadDetails:lead} });
    }

    return (
        <Box>
            <PatchListComp processList={processList} mystate={state} HandleInputs={HandleInputs} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleCloseAlert={handleCloseAlert} CustomSearch={CustomSearch} Reload={Reload} GetLeadDetails={GetLeadDetails} />
            {((getProcessList === true) ? GetProcessList(pnkstore, userData.getState().profile, userData.getState().username, userData.getState().orgId, userData.getState().token, HandleGetProcessListSuccessResponse) : "")}
            {((getPatchApi === true) ? GetPatchData(pnkstore, userData.getState().profile, userData.getState().username, search_data, perSet, setNo, userData.getState().token, HandleGetPatchDataSuccessResponse) : "")}
        </Box>
    )
}