import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Grid, Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';


import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';


export const DataRechurnComp = (props) => {


    return (
        <Box>
            <Box>
                <Grid container>
                    <Grid md={3}>
                        <Box paddingRight={2}>
                            <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Process</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.mystate.ddlProcessId}
                                    label="Process"
                                    key={'ddlProcessId'}
                                    onChange={(e) => props.HandleInputs(e, 'ddlProcessId')}
                                >
                                    {
                                        props.processList != undefined && props.processList.length > 0 ?
                                            props.processList.map((item, i) =>
                                                <MenuItem value={item._id}>{item.process_name}</MenuItem>
                                            )
                                            :
                                            null
                                    }
                                </Select>
                            </FormControl>
                        </Box>

                    </Grid>

                    {
                        props.mystate.filterListData.length > 0 ?

                            props.mystate.filterListData.map((item, i) =>
                                <Grid md={3} key={item.filter_name}>
                                    <Box paddingRight={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">{item.filter_name}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={props.mystate.ddlFilterData}
                                                label="Process"
                                                //helperText={props.mystate.ddlFilterDataErr}
                                                multiple
                                                onChange={(e) => props.HandleInputs(e, 'ddlFilterData', i)}
                                            >
                                                {
                                                    item.filter_options != undefined && item.filter_options.length > 0 ?
                                                        item.filter_options.map((elem, i) =>
                                                            <MenuItem value={elem.option_name}>{elem.option_name}</MenuItem>
                                                        )
                                                        :
                                                        null
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            )
                            : ''}


                    <Grid md={3}>
                        <Box marginTop={-1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker onChange={(e) => props.HandleInputs(e, 'txtStartDate')} label="Start Date" value={dayjs(props.mystate.txtStartDate)} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Grid>
                    <Grid md={3}>
                        <Box marginTop={-1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker onChange={(e) => props.HandleInputs(e, 'txtEndDate')} label="End Date" value={dayjs(props.mystate.txtEndDate)} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Grid>
                </Grid>


                <Grid container mt={5}>
                    <Grid md={4}></Grid>
                    <Grid md={4} textAlign={'center'}>
                        <Box mt={2}>
                            <Button disabled={props.mystate.rechurnData == true ? true : false} variant="contained" onClick={() => props.RechurnData()}>Rechurn Data</Button>
                        </Box>
                        {
                            props.mystate.rechurnData == true ?
    
                            <Box mt={2}>
                            <LinearProgress />
                            </Box>
    
                            :
    
                            <Box></Box>
                        }
                    </Grid>
                    <Grid md={4}></Grid>
                   
                </Grid>
            </Box>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={props.mystate.showAlert} autoHideDuration={2000} onClose={() => { props.handleCloseAlert() }}>
            <Alert
                onClose={() => { props.handleCloseAlert() }}
                severity={props.mystate.alertType}
                variant="filled"
                sx={{ width: '100%' }}

            >
                {props.mystate.alertMsg}
            </Alert>
        </Snackbar>
        </Box>


    );
}