import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

// Create a user store using zustand
export const useUserStore = create(persist(() => ({ userId: '', name: '', username: '', email: '', mobile: '', profile: '', reportingAgents: '', orgId: '', orgName: '', token: '', isLogin: false }), { name: 'userdetails', storage: createJSONStorage(() => localStorage) }))
// // export const useUserStore = create((set) => ({
// //   userData: {
// //   name: 'Ausaf Ahmad',
// //   username: 'ausi',
// //   email: 'ausi@gmail.com',
// //   mobile: '8887757417',
// //   profile: 'Admin',
// //   token: 'Test',
// //   }
// //   ,
// //   setUserData: (userdata) => set(({
// //     userData: {
// //       name: userdata.name,
// //       username: userdata.username,
// //       email: userdata.email,
// //       mobile: userdata.mobile,
// //       profile: userdata.profile,
// //       token: userdata.token
// //     }
// //   }),
// //   ),

// }))




