import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Box, Button, Card, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Skeleton from '@mui/material/Skeleton';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import Badge from '@mui/material/Badge';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';




export const LeadHistoryComp = (props) => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const FormatTime = (data) => {
    // const hours = Math.floor(data / 3600);
    // time = time - hours * 3600;
    // const minutes = Math.floor(time / 60);
    // const seconds = data - minutes * 60;
  }


  return (
    <Box>
      <Box>
        <Grid container>
          <Grid md={3}>
            <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={()=> props.NavigateTo('leads')}>
              Back
            </Button>
          </Grid>
        </Grid>
      </Box>
      {
        props.mystate.getLeadHistory == true ?
          <Box>
            <Grid container mt={2}>
              <Grid md={5} >
                <Skeleton animation="wave" variant="rectangular" height={250} />
              </Grid>
              <Grid md={1}></Grid>
              <Grid md={6}>
                <Skeleton animation="wave" variant="rectangular" height={350} />
              </Grid>
            </Grid>
          </Box>
          :
          <Box style={{ marginTop: '20px' }}>
            <Grid container>
              <Grid md={5}>
                {
                  props.mystate.leadHistoryData != undefined && props.mystate.leadHistoryData.length > 0 ?
                    <Box className="lead-details">
                      <Box className="lead-head">
                        <h2>{props.mystate.leadHistoryData[0].user_name}</h2>
                        <h4>{props.mystate.leadHistoryData[0].user_mobile}</h4>
                      </Box>
                      <Box className="lead-desc">
                        <Grid container>
                          <Grid md={6}>
                            <Box className="lead-desc-box">
                              <Typography className='lead-desc-head'>
                                Email
                              </Typography>
                              <Typography className='lead-desc-info'>
                                {props.mystate.leadHistoryData[0].user_email != undefined && props.mystate.leadHistoryData[0].user_email != '' ? props.mystate.leadHistoryData[0].user_email : 'dummy@gmail.com'}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid md={6}>
                            <Box className="lead-desc-box">
                              <Typography className='lead-desc-head'>
                                Lead City
                              </Typography>
                              <Typography className='lead-desc-info'>
                                {props.mystate.leadHistoryData[0].user_city}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid md={6}>
                            <Box className="lead-desc-box">
                              <Typography className='lead-desc-head'>
                                Lead Stage
                              </Typography>
                              <Typography className='lead-desc-info'>
                                {props.mystate.leadHistoryData[0].user_stage}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid md={6}>
                            <Box className="lead-desc-box">
                              <Typography className='lead-desc-head'>
                                Lead Id
                              </Typography>
                              <Typography className='lead-desc-info'>
                                {props.mystate.leadHistoryData[0]._id}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>

                      </Box>
                    </Box>
                    :
                    <Box></Box>
                }

              </Grid>
              <Grid md={7}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <Card variant="outlined">
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                          <Tab className='history-tab' label="All" value="1" />
                          <Tab className='history-tab' label="Calls" value="2" />
                          <Tab className='history-tab' label="Interactions" value="3" />
                        </TabList>
                      </Box>
                      <TabPanel value="1" style={{ padding: 0 }}>
                        <Box className="history-tree">
                          {
                            props.mystate.leadHistoryData.length > 0 ?
                              <Timeline sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                  flex: 0.2,
                                },
                              }}>
                                {
                                  props.mystate.leadHistoryData.map((item, i) =>
                                    item.type == 'Interaction' ?
                                      <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                          {item.added_on}
                                          <Badge style={{position:'relative', width:'100%', right:25}} color='primary' badgeContent={item.type}></Badge>
                                        </TimelineOppositeContent>
                                        
                                        <TimelineSeparator>
                                          <TimelineDot />
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          <Paper elevation={3}>
                                            <Card>
                                              <CardContent>
                                                <Grid container>
                                                  <Grid md={3}>
                                                    <Chip className='prox-reg' label={item.description != undefined && item.description.length > 1 ? item.description[1].value : 'NA'} />
                                                    <Typography className='prox-reg' mt={2} ml={1}>{'By' + ' ' + item.added_by}</Typography>
                                                  </Grid>
                                                  <Grid md={1}></Grid>
                                                  <Grid md={8}>
                                                    <Box>
                                                      {
                                                        item.description != undefined && item.description.length > 0 && item.description[0].fieldName == 'Notes' ?
                                                          <Box className="lead-history-box">
                                                            <Typography className='prox-reg bold small-tag'>{item.description[0].fieldName}</Typography>
                                                            <Typography className='prox-reg gray font-sm'>{item.description[0].value != undefined && item.description[0].value != '' ? item.description[0].value : 'Not Notes Found'}</Typography>
                                                          </Box>
                                                          :
                                                          <Box></Box>
                                                      }

                                                      {
                                                        item.description != undefined && item.description.length > 1 ?
                                                          <Grid container mt={2}>
                                                            <Grid md={4}>
                                                              <Box className="lead-history-box">
                                                                <Typography className='prox-reg bold font-sm'>{item.description[1].value + ' Reason'}</Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid md={1}></Grid>
                                                            <Grid md={7}>
                                                              <Box className="lead-history-box">
                                                                <Typography className='prox-reg gray font-sm'>{item.description[1].data != undefined && item.description[1].data.length > 0 ? item.description[1].data[0].value : 'NA'}</Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>

                                                          :

                                                          <Box></Box>
                                                      }

                                                    </Box>

                                                  </Grid>
                                                </Grid>
                                              </CardContent>
                                            </Card>
                                          </Paper>
                                        </TimelineContent>
                                      </TimelineItem>
                                      :
                                     item.type == 'Call Logs' ?
                                          <TimelineItem>
                                            <TimelineOppositeContent color="text.secondary">
                                              {item.added_on}
                                              <Badge style={{position:'relative', width:'100%', right:25}} color='secondary' badgeContent={item.type}></Badge>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                              <TimelineDot />
                                              <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                              <Paper elevation={3}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container>
                                                      <Grid md={3}>
                                                        <Chip className='prox-reg' label={'Outgoing'} />
                                                        <Typography className='prox-reg' mt={2} ml={1}>{item.added_by}</Typography>
                                                      </Grid>
                                                      <Grid md={1}></Grid>
                                                      <Grid md={8}>
                                                        <Box>
                                                          <Box className="lead-history-box">
                                                            <Typography className='prox-reg bold small-tag'>{'Duration'}</Typography>
                                                            <Typography className='prox-reg gray font-sm'>{item.description[0].call_duration + ' seconds'}</Typography>
                                                          </Box>

                                                        </Box>

                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Paper>
                                            </TimelineContent>
                                          </TimelineItem>
                                          :
                                          <TimelineItem>
                                            <TimelineOppositeContent color="text.secondary">
                                              {item.added_on}
                                              <Badge style={{position:'relative', width:'100%', right:25}} color='warning' badgeContent={item.type}></Badge>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                              <TimelineDot />
                                              <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                              <Card>
                                                <CardContent>
                                                  <Grid container>
                                                    <Grid md={6}>
                                                      <Chip className='prox-reg' label={item.type} />
                                                      <Typography className='prox-reg' mt={2} ml={1}>{item.added_by}</Typography>
                                                    </Grid>
                                                    <Grid md={1}></Grid>
                                                  </Grid>
                                                </CardContent>
                                              </Card>
                                            </TimelineContent>
                                          </TimelineItem>
                                          
                                  )
                                }
                              </Timeline>
                              :
                              <Box></Box>
                          }

                        </Box>
                      </TabPanel>
                      <TabPanel value="2" style={{ padding: 0 }}>
                        <Box className="history-tree">
                          {
                            props.mystate.leadHistoryData.length > 0 ?
                              <Timeline sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                  flex: 0.2,
                                },
                              }}>
                                {
                                  props.mystate.leadHistoryData.map((item, i) =>
                                    item.type == 'Call Logs' ?
                                      <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                          {item.added_on}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                          <TimelineDot />
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          <Paper elevation={3}>
                                            <Card>
                                              <CardContent>
                                                <Grid container>
                                                  <Grid md={3}>
                                                    <Chip className='prox-reg' label={'Outgoing'} />
                                                    <Typography className='prox-reg' mt={2} ml={1}>{item.added_by}</Typography>
                                                  </Grid>
                                                  <Grid md={1}></Grid>
                                                  <Grid md={8}>
                                                    <Box>
                                                      <Box className="lead-history-box">
                                                        <Typography className='prox-reg bold small-tag'>{'Duration'}</Typography>
                                                        <Typography className='prox-reg gray font-sm'>{item.description[0].call_duration + ' seconds'}</Typography>
                                                      </Box>

                                                    </Box>

                                                  </Grid>
                                                </Grid>
                                              </CardContent>
                                            </Card>
                                          </Paper>
                                        </TimelineContent>
                                      </TimelineItem>
                                      :
                                      <Box></Box>
                                  )
                                }
                              </Timeline>
                              :
                              <Box></Box>
                          }

                        </Box>
                      </TabPanel>
                      <TabPanel value="3" style={{ padding: 0 }}>
                        <Box className="history-tree">
                          {
                            props.mystate.leadHistoryData.length > 0 ?
                              <Timeline sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                  flex: 0.2,
                                },
                              }}>
                                {
                                  props.mystate.leadHistoryData.map((item, i) =>
                                    item.type == 'Interaction' ?
                                      <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                          {item.added_on}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                          <TimelineDot />
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          <Paper elevation={3}>
                                            <Card>
                                              <CardContent>
                                                <Grid container>
                                                  <Grid md={3}>
                                                    <Chip className='prox-reg' label={item.description != undefined && item.description.length > 1 ? item.description[1].value : 'NA'} />
                                                    <Typography className='prox-reg' mt={2} ml={1}>{'By' + ' ' + item.added_by}</Typography>
                                                  </Grid>
                                                  <Grid md={1}></Grid>
                                                  <Grid md={8}>
                                                    <Box>
                                                      {
                                                        item.description != undefined && item.description.length > 0 && item.description[0].fieldName == 'Notes' ?
                                                          <Box className="lead-history-box">
                                                            <Typography className='prox-reg bold small-tag'>{item.description[0].fieldName}</Typography>
                                                            <Typography className='prox-reg gray font-sm'>{item.description[0].value != undefined && item.description[0].value != '' ? item.description[0].value : 'Not Notes Found'}</Typography>
                                                          </Box>
                                                          :
                                                          <Box></Box>
                                                      }

                                                      {
                                                        item.description != undefined && item.description.length > 1 ?
                                                          <Grid container mt={2}>
                                                            <Grid md={4}>
                                                              <Box className="lead-history-box">
                                                                <Typography className='prox-reg bold font-sm'>{item.description[1].value + ' Reason'}</Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid md={1}></Grid>
                                                            <Grid md={7}>
                                                              <Box className="lead-history-box">
                                                                <Typography className='prox-reg gray font-sm'>{item.description[1].data != undefined && item.description[1].data.length > 0 ? item.description[1].data[0].value : 'NA'}</Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>

                                                          :

                                                          <Box></Box>
                                                      }

                                                    </Box>

                                                  </Grid>
                                                </Grid>
                                              </CardContent>
                                            </Card>
                                          </Paper>
                                        </TimelineContent>
                                      </TimelineItem>
                                      :
                                      <Box></Box>
                                  )
                                }
                              </Timeline>
                              :
                              <Box></Box>
                          }

                        </Box>
                      </TabPanel>
                    </TabContext>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Box>
      }

    </Box>


  );
}