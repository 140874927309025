import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LogsComp } from './logs';
import { GetLeadsFromStorage, GetProcessList, GetCallLogsList } from '../../models/process';
import { pnkstore } from '../../stores/pnk-store';
import { useUserStore } from '../../stores/user-store';
import { useNavigate } from 'react-router-dom';

export const CallLogs = () => {
    const userData = useUserStore;
    const [getProcessList, setGetProcessList] = useState(true);
    const [getCallLogList, setGetCallLogList] = useState(false);
    const [processList, setProcessList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [logsList, setLogsList] = useState([]);
    const [setNo, setSetNo] = useState(1);
    const [perSet, setPerSet] = useState(50);
    const [pageLength, setPageLength] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [ddlProcessId, setddlProcessId] = useState('');
    const [ddlAgentName, setddlAgentName] = useState('All');
    const [ddlDuration, setddlDuration] = useState('All');
    const [leadObj, setLeadObj] = useState({});
    const [txtStartDate, settxtStartDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' '));
    const [txtEndDate, settxtEndDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' '));
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const navigate = useNavigate();


    const state = {
        ddlProcessId: ddlProcessId,
        ddlAgentName: ddlAgentName,
        ddlDuration: ddlDuration,
        txtStartDate: txtStartDate,
        txtEndDate: txtEndDate,
        getCallLogList: getCallLogList,
        getProcessList: getProcessList,
        logsList: logsList,
        userList: userList,
        totalRecords: totalRecords,
        perSet: perSet,
        setNo: setNo,
        loginUserProfile: userData.getState().profile,
        reportingAgents: userData.getState().reportingAgents,
    }



    useEffect(() => {
        //  setGetProcessList(true)
    }, []);


    const handleChangePage = (event) => {
        let set_no = setNo;
        if (event.currentTarget.title == 'Go to next page') {
            set_no = set_no + 1;
        }
        else {
            set_no = set_no - 1;
        }


        setSetNo(set_no);

        setLeadObj({
            'org_id': userData.getState().orgId,
            'org_name': userData.getState().orgName,
            'user_type': userData.getState().profile,
            'ddlProcessId': ddlProcessId,
            'ddlProcessName': '',
            'ddlProcessAgent': ddlAgentName,
            'ddlDuration': ddlDuration,
            'txtStartDate': txtStartDate,
            'txtEndDate': txtEndDate,
            'limit': perSet,
            'set_no': set_no
        });
        setGetCallLogList(true);
        //  setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        let value = event.target.value;
        console.log(event.target.value);
        setPerSet(value);
        setSetNo(1);

        setLeadObj({
            'org_id': userData.getState().orgId,
            'org_name': userData.getState().orgName,
            'user_type': userData.getState().profile,
            'ddlProcessId': ddlProcessId,
            'ddlProcessName': '',
            'ddlProcessAgent': ddlAgentName,
            'ddlDuration': ddlDuration,
            'txtStartDate': txtStartDate,
            'txtEndDate': txtEndDate,
            'limit': value,
            'set_no': 1
        });
        setGetCallLogList(true);
        // setRowsPerPage(+event.target.value);
        // setPage(0);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
        setAlertMsg('');
    }

    const HandleInputs = (e, name) => {
        let value = ''
        if (name == 'txtStartDate' || name == 'txtEndDate') {
            value = e.$d;
        }
        else {
            value = e.target.value;
        }
        if (name == 'ddlProcessId') {
            let index = processList.findIndex((item) => value == item._id);
            if (index > -1) {
                setUserList((processList[index].process_members_details).sort());
            }
            else {
                setUserList([]);
            }
            let start_date = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ');
            let end_date = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ');
            setddlAgentName('All');
            setddlDuration('All');
            setddlProcessId(value);
            settxtStartDate(start_date);
            settxtEndDate(end_date);
            setPerSet(50);
            setSetNo(1);
            setLeadObj({
                'org_id': userData.getState().orgId,
                'org_name': userData.getState().orgName,
                'user_type': userData.getState().profile,
                'ddlProcessId': value,
                'ddlProcessName': processList[index].process_name,
                'ddlProcessAgent': 'All',
                'ddlDuration': 'All',
                'txtStartDate': start_date,
                'txtEndDate': end_date,
                'limit': 50,
                'set_no': 1
            });

            setGetCallLogList(true);
            setLogsList([]);
        }
        else if (name == 'ddlAgentName') {
            setddlAgentName(value);
            setPerSet(50);
            setSetNo(1);
            setLeadObj({
                'org_id': userData.getState().orgId,
                'org_name': userData.getState().orgName,
                'user_type': userData.getState().profile,
                'ddlProcessId': ddlProcessId,
                'ddlProcessName': '',
                'ddlProcessAgent': value,
                'ddlDuration': ddlDuration,
                'txtStartDate': txtStartDate,
                'txtEndDate': txtEndDate,
                'limit': 50,
                'set_no': 1
            });

            setGetCallLogList(true);
            setLogsList([]);
        }
        else if (name == 'ddlDuration') {
            setddlDuration(value);
            setPerSet(50);
            setSetNo(1);
            setLeadObj({
                'org_id': userData.getState().orgId,
                'org_name': userData.getState().orgName,
                'user_type': userData.getState().profile,
                'ddlProcessId': ddlProcessId,
                'ddlProcessName': '',
                'ddlProcessAgent': ddlAgentName,
                'ddlDuration': value,
                'txtStartDate': txtStartDate,
                'txtEndDate': txtEndDate,
                'limit': 50,
                'set_no': 1
            });
            setGetCallLogList(true);
            setLogsList([]);
        }
        else if (name == 'txtStartDate') {
            let date = new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ')
            settxtStartDate(date);
        }

        else if (name == 'txtEndDate') {
            let date = new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ')
            settxtEndDate(date);
        }
    }

    const CustomSearch = () => {

        if (txtStartDate == '' && txtEndDate != '') {
            // alert('WRONG');
            setShowAlert(true);
            setAlertMsg('Select Correct Date Range');
            return false;
        }

        if (txtStartDate != '' && txtEndDate != '') {
            if (txtEndDate < txtStartDate) {
                setShowAlert(true);
                setAlertMsg('Select Correct Date Range');
                return false;
            }
        }

        setPerSet(50);
        setSetNo(1);

        setLeadObj({
            'org_id': userData.getState().orgId,
            'org_name': userData.getState().orgName,
            'user_type': userData.getState().profile,
            'ddlProcessId': ddlProcessId,
            'ddlProcessName': '',
            'ddlProcessAgent': ddlAgentName,
            'ddlDuration': ddlDuration,
            'txtStartDate': txtStartDate,
            'txtEndDate': txtEndDate,
            'limit': 50,
            'set_no': 1
        });

        setGetCallLogList([]);
        setGetCallLogList(true);
    }

    const Reload = () => {

        let startDate = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ');
        let endDate = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ');

        settxtStartDate(startDate);
        settxtEndDate(endDate);
        setddlAgentName('All');
        setddlDuration('All');
        setSetNo(1);
        setPerSet(50);

        setLeadObj({
            'org_id': userData.getState().orgId,
            'org_name': userData.getState().orgName,
            'user_type': userData.getState().profile,
            'ddlProcessId': ddlProcessId,
            'ddlProcessName': '',
            'ddlProcessAgent': 'All',
            'ddlDuration': 'All',
            'txtStartDate': startDate,
            'txtEndDate': endDate,
            'limit': 50,
            'set_no': 1
        });

        setGetCallLogList([]);
        setGetCallLogList(true);


    }

    const HandleGetProcessListSuccessResponse = (res) => {
        if (res != false && res != 'error') {
            let processListData = res.data.processlist.rows;
            setProcessList(res.data.processlist.rows);
            setddlProcessId(res.data.processlist.rows[1] != undefined ? res.data.processlist.rows[1]._id : res.data.processlist.rows[0]._id);
            setUserList((processListData[1].process_members_details).sort());
            setGetCallLogList(true);
            setddlAgentName('All');
            setLeadObj({
                'org_id': userData.getState().orgId,
                'org_name': userData.getState().orgName,
                'user_type': userData.getState().profile,
                'ddlProcessId': res.data.processlist.rows[1] != undefined ? res.data.processlist.rows[1]._id : res.data.processlist.rows[0]._id,
                'ddlProcessName': res.data.processlist.rows[1] != undefined ? res.data.processlist.rows[1].process_name : res.data.processlist.rows[0].process_name,
                'ddlProcessAgent': ddlAgentName,
                'ddlDuration': 'All',
                'txtStartDate': txtStartDate,
                'txtEndDate': txtEndDate,
                'limit': perSet,
                'set_no': setNo
            })

            // let agent_name = user_data.ddlProcessAgent;
            // let user_mobile = user_data.ddlUserMobile;
            // let duration = user_data.ddlDuration;
            // let start_date = user_data.txtStartDate;
            // let end_date = user_data.txtEndDate;
        }
        else {
            setProcessList([]);
        }
        setGetProcessList(false);
    }

    const HandleGetCallLogListSuccessResponse = (res) => {
        if (res != false && res != 'error') {
            setTotalRecords(res.data.calllogslist.metarow.totalRecordsInDb);
            console.log(res.data.calllogslist.rows)
            setLogsList(res.data.calllogslist.rows);
        }
        else {
            setTotalRecords(0);
            setLogsList([]);
        }
        setGetCallLogList(false);
    }

    const GetLeadHistory = (storageId, custMobile) => {

        let hideData = storageId + '-' + custMobile;

        let hideId = window.btoa(hideData);

        navigate('/leadhistory/' + hideId, { replace: true, store: storageId });
    }

    return (
        <Box>
            <LogsComp processList={processList} mystate={state} HandleInputs={HandleInputs} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleCloseAlert={handleCloseAlert} GetLeadHistory={GetLeadHistory} CustomSearch={CustomSearch} Reload={Reload} />
            {((getProcessList === true) ? GetProcessList(pnkstore, userData.getState().profile, userData.getState().username, userData.getState().orgId, userData.getState().token, HandleGetProcessListSuccessResponse) : "")}
            {((getCallLogList === true) ? GetCallLogsList(pnkstore, leadObj, userData.getState().token, HandleGetCallLogListSuccessResponse) : "")}
        </Box>
    )
}