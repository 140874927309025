import { Box } from '@mui/material';
import React, { useState } from 'react';
import MiniDrawer from '../sidebar/sidebar';
import { useUserStore } from '../../stores/user-store';
import { useNavigate } from 'react-router-dom';
import Login from '../../components/Login';
import { Dashboard } from '../../components/Dashboard';


export const SinglePageLayout = (props) => {

    const userData = useUserStore;
    const navigate = useNavigate();

    if (userData.getState().isLogin != true) {
        return (<Box>
            <Login />
        </Box>);
    }


    const navigateTo = (elem) => {
        navigate('/' + elem, { replace: true });
    }
    // console.log(userData.getState().isLogin);
    // console.log(props.component.name);
    return (
        <Box>
            {
                userData.getState().isLogin == true ?
                    props.component.name == 'Login' ?
                        <Box>
                            <MiniDrawer></MiniDrawer>
                            <div id="page-wrapper">
                                <Dashboard />
                            </div>
                        </Box>
                        :
                        props.component.name == undefined ?
                            <Box>
                                <MiniDrawer></MiniDrawer>
                                <div id="page-wrapper">
                                    <Dashboard />
                                </div>
                            </Box>
                            :
                            <Box>
                                <MiniDrawer></MiniDrawer>
                                <div id="page-wrapper">
                                    <props.component />
                                </div>
                            </Box>
                    :
                    navigate('/login', { replace: true })
            }

        </Box>
    )

}