import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LeadsComp } from './leads';
import { GetLeadsFromStorage, GetProcessList } from '../../models/process';
import { pnkstore } from '../../stores/pnk-store';
import { useUserStore } from '../../stores/user-store';
import { useNavigate } from 'react-router-dom';

export const Leads = () => {
    const userData = useUserStore;
    const [getProcessList, setGetProcessList] = useState(true);
    const [getLeadList, setGetLeadList] = useState(false);
    const [processList, setProcessList] = useState([]);
    const [leadList, setLeadList] = useState([]);
    const [setNo, setSetNo] = useState(1);
    const [perSet, setPerSet] = useState(50);
    const [pageLength, setPageLength] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [ddlProcessId, setddlProcessId] = useState('');
    const [leadObj, setLeadObj] = useState({});
    const navigate = useNavigate();


    const state = {
        ddlProcessId: ddlProcessId,
        getLeadList: getLeadList,
        getProcessList: getProcessList,
        leadList: leadList,
        totalRecords: totalRecords,
        perSet: perSet,
        setNo: setNo,
    }



    useEffect(() => {
        
        //  setGetProcessList(true)
    }, []);


    const handleChangePage = (event) => {
        let set_no = setNo;
        if (event.currentTarget.title == 'Go to next page') {
            set_no = set_no + 1;
        }
        else {
            set_no = set_no - 1;
        }


        setSetNo(set_no);

        setLeadObj({
            'org_id': userData.getState().orgId,
            'org_name': userData.getState().orgName,
            'ddlProcessId': ddlProcessId,
            'ddlProcessName': '',
            'per_set': perSet,
            'set_no': set_no
        });
        setGetLeadList(true);
        //  setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        let value = event.target.value;
        console.log(event.target.value);
        setPerSet(value);
        setSetNo(1);

        setLeadObj({
            'org_id': userData.getState().orgId,
            'org_name': userData.getState().orgName,
            'ddlProcessId': ddlProcessId,
            'ddlProcessName': '',
            'per_set': value,
            'set_no': 1
        });
        setGetLeadList(true);
        // setRowsPerPage(+event.target.value);
        // setPage(0);
    };


    const HandleInputs = (e, name) => {
        let value = e.target.value;

        if (name == 'ddlProcessId') {
            setddlProcessId(value);
            setLeadObj({
                'org_id': userData.getState().orgId,
                'org_name': userData.getState().orgName,
                'ddlProcessId': value,
                'ddlProcessName': '',
                'per_set': perSet,
                'set_no': setNo
            });

            setGetLeadList(true);
            setLeadList([]);
        }
    }


    const HandleGetProcessListSuccessResponse = (res) => {
        if (res != false && res != 'error') {
            setProcessList(res.data.processlist.rows);
            setddlProcessId(res.data.processlist.rows[1]._id);
            setGetLeadList(true);
            setLeadObj({
                'org_id': userData.getState().orgId,
                'org_name': userData.getState().orgName,
                'ddlProcessId': res.data.processlist.rows[1]._id,
                'ddlProcessName': res.data.processlist.rows[1].process_name,
                'per_set': perSet,
                'set_no': setNo
            })
        }
        else {
            setProcessList([]);
        }
        setGetProcessList(false);
    }

    const HandleGetLeadListSuccessResponse = (res) => {
        if (res != false && res != 'error') {
            setTotalRecords(res.data.storageleadlist.metarow.totalRecordsInDb);
            setLeadList(res.data.storageleadlist.rows);


        }
        else {
            setTotalRecords(0);
            setLeadList([]);
        }
        setGetLeadList(false);
    }

    const GetLeadHistory = (storageId, custMobile) => {

        let hideData = storageId + '-' + custMobile;

        let hideId = window.btoa(hideData);

        navigate('/leadhistory/' + hideId, { replace: true, store: storageId });
    }
   

    return (
        <Box>
            <LeadsComp processList={processList} mystate={state} HandleInputs={HandleInputs} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} GetLeadHistory={GetLeadHistory} />
            {((getProcessList === true) ? GetProcessList(pnkstore, userData.getState().profile, userData.getState().username, userData.getState().orgId, userData.getState().token, HandleGetProcessListSuccessResponse) : "")}
            {((getLeadList === true) ? GetLeadsFromStorage(pnkstore, leadObj, userData.getState().profile, userData.getState().username, perSet, setNo, userData.getState().token, HandleGetLeadListSuccessResponse) : "")}
        </Box>
    )
}