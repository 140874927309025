import * as React from 'react';
import { Box, Grid, Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';


export const UserDetailsComp = (props) => {


    return (
        <Box>
            <Box className="form-head">
                <Grid container>
                    <Grid md={9}>
                        <Box>
                            {
                                props.mystate.mode == 'edit' ?
                                    <Typography variant="h5" component="h5">
                                        Update User
                                    </Typography>
                                    :
                                    <Typography variant="h5" component="h5">
                                        Add Users
                                    </Typography>
                            }

                        </Box>
                    </Grid>
                    <Grid md={3}>
                        <Box textAlign={'end'}>
                            <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => props.NavigateTo('users')}>
                                Back
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

            </Box>

            <Box style={{ marginTop: '20px' }}>
                <Grid container>
                    <Grid md={3}>
                        <Box p={2}>
                            <TextField className="text-input" id="txtUserName" name='txtUserName' disabled={props.mystate.mode == 'edit' ? true : false} error={props.mystate.txtUserNameErr == '' ? false : true} helperText={props.mystate.txtUserNameErr} onChange={(e) => props.HandleInputs(e, 'txtUserName')} value={props.mystate.txtUserName} label="User Name" variant="outlined" />
                        </Box>
                    </Grid>
                    <Grid md={3}>
                        <Box p={2}>
                            <TextField className="text-input" id="txtUserMobile" name='txtUserMobile' error={props.mystate.txtUserMobileErr == '' ? false : true} helperText={props.mystate.txtUserMobileErr} onChange={(e) => props.HandleInputs(e, 'txtUserMobile')} value={props.mystate.txtUserMobile} label="User Mobile" variant="outlined" />
                        </Box>
                    </Grid>
                    <Grid md={3}>
                        <Box p={2}>
                            <TextField className="text-input" id="txtUserEmail" name='txtUserEmail' error={props.mystate.txtUserEmailErr == '' ? false : true} helperText={props.mystate.txtUserEmailErr} onChange={(e) => props.HandleInputs(e, 'txtUserEmail')} value={props.mystate.txtUserEmail} label="User Email" variant="outlined" />
                        </Box>
                    </Grid>
                    <Grid md={3}>
                        <Box p={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.mystate.ddlUserType}
                                    label="User Type"
                                    error={props.mystate.ddlUserTypeErr == '' ? false : true}
                                    // helperText={props.mystate.ddlUserTypeErr}
                                    onChange={(e) => props.HandleInputs(e, 'ddlUserType')}
                                >
                                    <MenuItem value={'Agent'}>{'Agent'}</MenuItem>
                                    <MenuItem value={'Admin'}>{'Admin'}</MenuItem>
                                </Select>
                                <FormHelperText style={{ color: 'red' }}>{props.mystate.ddlUserTypeErr}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid md={3}>
                        <Box p={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Process</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.mystate.ddlAssignedProcess}
                                    label="Process"
                                    error={props.mystate.ddlAssignedProcessErr == '' ? false : true}
                                    //helperText={props.mystate.ddlAssignedProcessErr}
                                    multiple
                                    onChange={(e) => props.HandleInputs(e, 'ddlAssignedProcess')}
                                >
                                    {
                                        props.processList != undefined && props.processList.length > 0 ?
                                            props.processList.map((item, i) =>
                                                <MenuItem value={item._id}>{item.process_name}</MenuItem>
                                            )
                                            :
                                            null
                                    }
                                </Select>
                                <FormHelperText style={{ color: 'red' }}>{props.mystate.ddlAssignedProcessErr}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid md={3}>
                        <Box p={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Reporting To</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.mystate.ddlUserReportsTo}
                                    label="Reporting To"
                                    error={props.mystate.ddlUserReportsToErr == '' ? false : true}
                                    //  helperText={props.mystate.ddlUserReportsToErr}
                                    multiple
                                    onChange={(e) => props.HandleInputs(e, 'ddlUserReportsTo')}
                                >
                                    {
                                        props.mystate.userList != undefined && props.mystate.userList.length > 0 ?
                                            props.mystate.userList.map((item, i) =>
                                                <MenuItem value={item.name}>{item.name}</MenuItem>
                                            )
                                            :
                                            null
                                    }
                                </Select>
                                <FormHelperText style={{ color: 'red' }}>{props.mystate.ddlUserReportsToErr}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid md={12}>
                        <Box mt={2} textAlign={'center'}>
                            {
                                props.mystate.mode == 'add' ?

                                    props.mystate.addUser == true ?

                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<SaveIcon />}
                                            variant="outlined"
                                            className=''
                                        >
                                            Add User
                                        </LoadingButton>
                                        :

                                        <Button variant="contained" onClick={() => props.AddUser()}>Add New User</Button>
                                    :
                                    props.mystate.editUser == true ?

                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<SaveIcon />}
                                            variant="outlined"
                                            className=''
                                        >
                                            Update User
                                        </LoadingButton>
                                        :
                                          props.mystate.txtUserName == '' ?
                                          ''
                                          :
                                        <Button variant="contained" onClick={() => props.AddUser()}>Update User</Button>
                            }

                        </Box>
                    </Grid>
                </Grid>

            </Box>
        </Box>


    );
}