import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UsersComp } from './users';
import { GetProcessList, RemoveUser } from '../../models/process';
import { pnkstore } from '../../stores/pnk-store';
import { useUserStore } from '../../stores/user-store';
import { useNavigate, useLocation } from 'react-router-dom';


export const Users = () => {
    const userData = useUserStore;
    const [getProcessList, setGetProcessList] = useState(false);
    const [processList, setProcessList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [ddlProcessId, setddlProcessId] = useState('');
    const [alertBox, setAlertBox] = useState(false);
    const [userName, setUserName] = useState('');
    const [deleteUser, setDeleteUser] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();


    const state = {
        ddlProcessId: ddlProcessId,
        getProcessList: getProcessList,
        userList: userList,


        alertBox: alertBox,
        userName: userName,
        loginUserProfile: userData.getState().profile
    }



    useEffect(() => {
        let currentProcessId = '';
        if (location.state !== undefined && location.state !== null) {
            currentProcessId = location.state.processId;
        }
        if (currentProcessId !== undefined && currentProcessId !== null && currentProcessId !== '') {
            setddlProcessId(currentProcessId);
        }
        setGetProcessList(true)
    }, []);


    const HandleInputs = (e, name) => {
        let value = e.target.value;

        if (name === 'ddlProcessId') {
            setddlProcessId(value);

            let index = processList.findIndex((item) => value === item._id);

            if (index > -1) {
                setUserList(processList[index].process_members_details);
            }
            else {
                setUserList([]);
            }
        }
    }

    const HandleCloseAlert = (type) => {
        if (type == 'No') {
            setAlertBox(false);
        }
        else {

            setDeleteUser(true);
            //  setAlertBox(false)
        }
    }


    const HandleGetProcessListSuccessResponse = (res) => {
        if (res != false && res != 'error') {
            let processListData = res.data.processlist.rows
            setProcessList(processListData);
            if (ddlProcessId != '') {
                let index = processListData.findIndex((item) => ddlProcessId == item._id);
                if (index > -1) {
                    setUserList(processListData[index].process_members_details);
                }
                else {
                    setUserList([]);
                }
            }
            else {
                setddlProcessId(res.data.processlist.rows[1]._id);
                setUserList(res.data.processlist.rows[1].process_members_details);
            }

        }
        else {
            setProcessList([]);
        }
        setGetProcessList(false);
    }



    const NavigateTo = (route) => {
        navigate('/' + route, { state: { processId: ddlProcessId, userList: userList } });
    }

    const DeleteUser = (user_name) => {
        setAlertBox(true)
        setUserName(user_name);
    }

    const RemoveUserResponse = (res) => {
        if (res != false && res != 'error') {
            setAlertBox(false);
            setGetProcessList(true);
        }
        else {

        }

        setDeleteUser(false);
    }

    const GetUserDetails = (user_name) => {
        NavigateTo('user/' + user_name);
    }


    return (
        <Box>
            <UsersComp processList={processList} mystate={state} HandleInputs={HandleInputs} HandleCloseAlert={HandleCloseAlert} NavigateTo={NavigateTo} DeleteUser={DeleteUser} GetUserDetails={GetUserDetails} />
            {((getProcessList === true) ? GetProcessList(pnkstore, userData.getState().profile, userData.getState().username, userData.getState().orgId, userData.getState().token, HandleGetProcessListSuccessResponse) : "")}
            {((deleteUser === true) ? RemoveUser(pnkstore, userData.getState().profile, userData.getState().username, userName, userData.getState().orgId, userData.getState().token, RemoveUserResponse) : '')}
        </Box>
    )
}