import * as React from 'react';
import { Box, Grid, Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import SearchIcon from '@mui/icons-material/Search';
import CachedIcon from '@mui/icons-material/Cached';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const GetTimeFormat = (time) => {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    hours = Math.floor(time / 3600);
    minutes = Math.floor((time - (hours * 3600)) / 60);
    seconds = time - (hours * 3600) - (minutes * 60);

    if (hours == 0) {
        hours = '';
    }
    else {
        hours = hours + ' H ';
    }


    if (minutes == 0) {
        minutes = '';
    }
    else {
        minutes = minutes + ' M ';
    }

    if (seconds == 0) {
        seconds = '';
    }
    else {
        seconds = seconds + ' S ';
    }


    let final_time = hours + minutes + seconds;

    if (final_time == '') {
        final_time = 0
    }

    return final_time;
}

export const DashboardComp = (props) => {


    return (
        <Box>
            <Box>
                <Grid container>
                    <Grid md={2}>
                        <Box paddingRight={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Process</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.mystate.ddlProcessId}
                                    label="Process"
                                    onChange={(e) => props.HandleInputs(e, 'ddlProcessId')}
                                >
                                    {
                                        props.processList != undefined && props.processList.length > 0 ?
                                            props.processList.map((item, i) =>
                                                <MenuItem value={item._id}>{item.process_name}</MenuItem>
                                            )
                                            :
                                            null
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    {props.mystate.reportingAgents != undefined && (props.mystate.reportingAgents).length > 0 ?
                        <Grid md={2}>
                            <Box paddingRight={2}>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Agents</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={props.mystate.ddlAgentName}
                                        label="Agenst"
                                        onChange={(e) => props.HandleInputs(e, 'ddlAgentName')}
                                    >
                                        {/* {props.mystate.reportingAgents != undefined && (props.mystate.reportingAgents).length > 0 ? */}
                                        <MenuItem selected value={'All'}>{'All'}</MenuItem>
                                        {/* : ''} */}
                                        {
                                            props.mystate.userList != undefined && props.mystate.userList.length > 0 ?
                                                props.mystate.userList.map((item, i) =>
                                                    // item.role == 'Agent' ?
                                                    props.mystate.loginUserProfile.includes('Super-Admin') ?
                                                        <MenuItem value={item.name}>{item.name}</MenuItem>
                                                        :
                                                        (props.mystate.reportingAgents).includes(item.name) ?
                                                            <MenuItem value={item.name}>{item.name}</MenuItem>
                                                            :
                                                            ''
                                                )
                                                :
                                                null
                                        }
                                    </Select>
                                </FormControl>
                            </Box>

                        </Grid>
                        : ''}

                    <Grid md={3}>
                        <Box marginTop={-1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker onChange={(e) => props.HandleInputs(e, 'txtStartDate')} label="Start Date" value={dayjs(props.mystate.txtStartDate)} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Grid>
                    <Grid md={3}>
                        <Box marginTop={-1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker onChange={(e) => props.HandleInputs(e, 'txtEndDate')} label="End Date" value={dayjs(props.mystate.txtEndDate)} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Grid>

                    <Grid md={2}>
                        <Box textAlign={'end'}>
                            <Button style={{ margin: 10 }} variant="outlined" onClick={() => props.CustomSearch()}><SearchIcon></SearchIcon></Button>
                            <Button style={{ margin: 10 }} variant="outlined" onClick={() => props.Reload()}><CachedIcon></CachedIcon></Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box style={{ marginTop: '20px' }}>
                {
                    props.mystate.showLoader1 == false ?
                        <Box>
                            <Grid container>
                                <Grid md={12}>
                                    <Box mb={1}>
                                        <Typography variant='h5' component={'h5'}>Call Logs</Typography>
                                    </Box>
                                </Grid>
                                <Grid md={2}>
                                    <Card style={{ width: '95%' }}>
                                        <CardContent>
                                            <Badge style={{ width: '100px', position: 'relative', right: 65, bottom: 10 }} color='primary' badgeContent={'Attempted'}></Badge>
                                            <Typography variant="h6" component="div">
                                                {props.mystate.callCountData.attempted_calls}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid md={2}>
                                    <Card style={{ width: '95%' }}>
                                        <CardContent>
                                            <Badge style={{ width: '100px', position: 'relative', right: 65, bottom: 10 }} color='success' badgeContent={'Connected'}></Badge>
                                            <Typography variant="h6" component="div">
                                                {props.mystate.callCountData.connected_calls}

                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid md={2}>
                                    <Card style={{ width: '95%' }}>
                                        <CardContent>
                                            <Badge style={{ width: '100px', position: 'relative', right: 65, bottom: 10 }} color='error' badgeContent={'NotConnected'}></Badge>
                                            <Typography variant="h6" component="div">
                                                {props.mystate.callCountData.not_connected_calls}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid md={2}>
                                    <Card style={{ width: '95%' }}>
                                        <CardContent>
                                            <Badge style={{ width: '100px', position: 'relative', right: 65, bottom: 10 }} color='warning' badgeContent={'Incoming'}></Badge>
                                            <Typography variant="h6" component="div">
                                                {props.mystate.callCountData.incoming_calls}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid md={2}>
                                    <Card style={{ width: '95%' }}>
                                        <CardContent>
                                            <Badge style={{ width: '100px', position: 'relative', right: 65, bottom: 10 }} color='success' badgeContent={'Total Talktime'}></Badge>
                                            <Typography variant="h6" component="div">
                                                {GetTimeFormat(props.mystate.callCountData.call_duration)}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid md={2}>
                                    <Card style={{ width: '95%' }}>
                                        <CardContent>
                                            <Badge style={{ width: '100px', position: 'relative', right: 65, bottom: 10 }} color='success' badgeContent={'Avg Talktime'}></Badge>
                                            <Typography variant="h6" component="div">
                                                {
                                                    props.mystate.callCountData.call_duration == 0 || props.mystate.callCountData.connected_calls == 0 ?
                                                        0
                                                        :
                                                        GetTimeFormat(Math.floor(props.mystate.callCountData.call_duration / props.mystate.callCountData.connected_calls))
                                                }

                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                        :
                        <Box>
                            <Grid container>
                                <Grid md={2}>
                                    <Box width={180}>
                                        <Skeleton height={140} />
                                    </Box>
                                </Grid>

                                <Grid md={2}>
                                    <Box width={180}>
                                        <Skeleton height={140} />
                                    </Box>
                                </Grid>

                                <Grid md={2}>
                                    <Box width={180}>
                                        <Skeleton height={140} />
                                    </Box>
                                </Grid>

                                <Grid md={2}>
                                    <Box width={180}>
                                        <Skeleton height={140} />
                                    </Box>
                                </Grid>

                                <Grid md={2}>
                                    <Box width={180}>
                                        <Skeleton height={140} />
                                    </Box>
                                </Grid>

                                <Grid md={2}>
                                    <Box width={180}>
                                        <Skeleton height={140} />
                                    </Box>
                                </Grid>



                            </Grid>
                        </Box>
                }

            </Box>


            <Box style={{ marginTop: '20px' }}>
                {
                    props.mystate.showLoader2 == false ?
                        props.mystate.callFilterData.length > 0 ?
                            props.mystate.callFilterData.map((item, i) =>
                                <Box>
                                    <Box mb={1}>
                                        <Typography variant='h5' component={'h5'}>{item.Name}</Typography>
                                    </Box>

                                    {
                                        item.data.length > 0 ?
                                            <Grid container>
                                                {
                                                    item.data.map((elem, j) =>
                                                        <Grid md={2}>
                                                            <Card style={{ margin: 5 }}>
                                                                <CardContent>
                                                                    <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                                        {elem.name}
                                                                    </Typography>
                                                                    <Typography variant="h6" component="div">
                                                                        {elem.count}
                                                                    </Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    )
                                                }

                                            </Grid>
                                            :
                                            ''
                                    }
                                </Box>
                            )

                            :
                            <Box>

                            </Box>
                        :
                        <Box>
                            <Grid container>
                                <Grid md={2}>
                                    <Box width={170}>
                                        <Skeleton height={170} />
                                    </Box>
                                </Grid>
                                <Grid md={2}>
                                    <Box width={170}>
                                        <Skeleton height={170} />
                                    </Box>
                                </Grid>
                                <Grid md={2}>
                                    <Box width={170}>
                                        <Skeleton height={170} />
                                    </Box>
                                </Grid>
                                <Grid md={2}>
                                    <Box width={170}>
                                        <Skeleton height={170} />
                                    </Box>
                                </Grid>
                                <Grid md={2}>
                                    <Box width={170}>
                                        <Skeleton height={170} />
                                    </Box>
                                </Grid>
                                <Grid md={2}>
                                    <Box width={170}>
                                        <Skeleton height={170} />
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>
                }

            </Box>




            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={props.mystate.showAlert} autoHideDuration={2000} onClose={() => { props.handleCloseAlert() }}>
                <Alert
                    onClose={() => { props.handleCloseAlert() }}
                    severity={'error'}
                    variant="filled"
                    sx={{ width: '100%' }}

                >
                    {props.mystate.alertMsg}
                </Alert>
            </Snackbar>
        </Box>


    );
}