import React, { useState } from 'react';
import { Box, Button, Container, FormHelperText, Grid, TextField } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Logo from '../../assets/images/calleyo.jpg'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const LoginComp = (props) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={12} lg={8}>

                    <Box className="bg-light">
                        <Box>
                            <Container>
                                <Box className="calleyo-login-head">
                                    <h1>Calleyo</h1>
                                    <h2>Calling Application CRM</h2>
                                </Box>
                                <Box className="calleyo-login-logo">
                                    <img src={Logo} />
                                </Box>
                            </Container>

                        </Box>

                    </Box>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4}>
                    <Box className="bg-dark">
                        <Box className="calleyo-login-form">
                            <Box className="login-form-logo">
                                <img src={Logo} />
                            </Box>
                            <Box className="">
                                <h4>Welcome to calleyo</h4>
                                <p>Please Sign in to your account and start the calling</p>
                            </Box>
                            <Box className="input-box">
                                <TextField className="text-input" id="txtNumber" name='txtNumber' onKeyDown={(e) => { if (e.key === "Enter") props.SignIn(); }} onChange={(e) => props.HandleInputs(e, 'txtNumber')} value={props.state.txtNumber} error={props.state.txtNumberErr == '' ? false : true} helperText={props.state.txtNumberErr} label="Username" variant="outlined" />
                            </Box>
                            <Box className="input-box">
                                <FormControl variant="outlined" className='text-input'>
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        className='text-input'
                                        onKeyDown={(e) => { if (e.key === "Enter") props.SignIn(); }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                        error={props.state.txtPasswordErr == '' ? false : true}
                                        helperText={props.state.txtPasswordErr}
                                        value={props.state.txtPassword}
                                        onChange={(e) => props.HandleInputs(e, 'txtPassword')}
                                    />
                                </FormControl>
                                {
                                    <FormHelperText error id="accountId-error">
                                        {props.state.txtPasswordErr}
                                    </FormHelperText>
                                }
                            </Box>
                            <Box className="input-box">
                                {
                                    props.login == false ?
                                        <Button className="form-btn crm-submit-btn" variant="contained" onClick={() => props.SignIn()}>Sign In</Button>
                                        :
                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<SaveIcon />}
                                            variant="outlined"
                                            className='w100'
                                        >
                                            Sign In
                                        </LoadingButton>
                                }



                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Snackbar open={props.state.showAlert} autoHideDuration={3000} onClose={()=>{props.handleCloseAlert()}}>
                <Alert
                    onClose={()=>{props.handleCloseAlert()}}
                    severity={props.state.alertType}
                    variant="filled"
                    sx={{ width: '100%' }}
                    
                >
                {props.state.alertMsg}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default LoginComp;