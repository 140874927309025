import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GetProcessDetails, GetProcessList, GetCallLogsCountByProcess } from '../../models/process';
import { pnkstore } from '../../stores/pnk-store';
import { useUserStore } from '../../stores/user-store';
import { useNavigate } from 'react-router-dom';
import { DashboardCallComp } from './dashboard';


export const DashboardCall = () => {
    const userData = useUserStore;
    const [getProcessList, setGetProcessList] = useState(true);
    const [processList, setProcessList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [callCountData, setCallCountData] = useState({});
    const [showLoader1, setshowLoader1] = useState(true);
    const [ddlProcessId, setddlProcessId] = useState('');
    const [ddlAgentName, setddlAgentName] = useState('All');
    const [alertBox, setAlertBox] = useState(false);
    const [userName, setUserName] = useState('');
    const [getProcessDetails, setGetProcessDetails] = useState(false);
    const [getCallLogsCount, setGetCallLogsCount] = useState(false);
    const [searchObj, setSearchObj] = useState({});
    const [filtersArray, setFiltersArray] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [txtStartDate, settxtStartDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' '));
    const [txtEndDate, settxtEndDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' '));
    const navigate = useNavigate();


    const state = {
        ddlProcessId: ddlProcessId,
        ddlAgentName: ddlAgentName,
        getProcessList: getProcessList,
        userList: userList,
        alertBox: alertBox,
        userName: userName,
        loginUserProfile: userData.getState().profile,
        reportingAgents: userData.getState().reportingAgents,
        txtStartDate: txtStartDate,
        txtEndDate: txtEndDate,
        callCountData: callCountData,
        getCallLogsCount: getCallLogsCount,
        getProcessDetails:getProcessDetails,
        showLoader1: showLoader1,
        showAlert: showAlert,
        alertMsg: alertMsg
    }

    useEffect(() => {

    }, []);


    const NavigateTo = (route) => {
        navigate('/' + route, { state: { processId: ddlProcessId } });
    }


    const HandleInputs = (e, name) => {
        let value = '';

        if (name == 'txtStartDate' || name == 'txtEndDate') {
            value = e.$d;
        }
        else {
            value = e.target.value;
        }

        if (name == 'ddlProcessId') {

            setddlProcessId(value);

            let index = processList.findIndex((item) => value == item._id);

            if (index > -1) {
                setUserList((processList[index].process_members_details).sort());
            }
            else {
                setUserList([]);
            }

            setddlAgentName('All');
            setGetProcessDetails(true);
        }

        else if (name == 'ddlAgentName') {
            setddlAgentName(value);

            setSearchObj({
                orgId: userData.getState().orgId,
                processId: ddlProcessId,
                agentName: value,
                txtStartDate: txtStartDate,
                txtEndDate: txtEndDate,
                filtersArray: filtersArray,
            });

            setshowLoader1(true);
            setGetCallLogsCount(true);

        }



        else if (name == 'txtStartDate') {
            let date = new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ')
            settxtStartDate(date);
        }

        else if (name == 'txtEndDate') {
            let date = new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ')
            settxtEndDate(date);
        }

    }

    const HandleCloseAlert = (type) => {
        if (type == 'No') {
            setAlertBox(false);
        }
        else {
        }
    }


    const HandleGetProcessListSuccessResponse = (res) => {
        if (res != false && res != 'error') {
            let processListData = res.data.processlist.rows
            setProcessList(processListData);
            setddlProcessId(processListData[1] != undefined ? processListData[1]._id : processListData[0]._id);
            setUserList(processListData[1] != undefined ? (processListData[1].process_members_details).sort() : (processListData[0].process_members_details).sort());
            setGetProcessDetails(true);
        }
        else {
            setProcessList([]);
        }
        setGetProcessList(false);
    }

    const HandleProcessDetailsResponse = (res) => {

        if (res != 'error' && res != false) {
            let data = res.data.processdetails.rows;
            let filtersArray = [];
            data.forEach((element, j) => {
                if (element.field_as_filter == 'Yes') {
                    let options = element.options_arr.split(',');
                    let optionsArr = [];
                    options.forEach((opt, o) => {
                        optionsArr.push({ 'name': opt, 'count': 0 });
                        if (options.length == o + 1) {

                            let filterData = {
                                'Name': element.field_name,
                                'data': optionsArr
                            }
                            filtersArray.push(filterData);
                        }
                    })
                }

                if (element.field_category == 'fixed') {
                    // this.setState({
                    //     [element.field_id]: element.field_name
                    // });

                }

                if (data.length == j + 1) {
                    setSearchObj({
                        orgId: userData.getState().orgId,
                        processId: ddlProcessId,
                        agentName: ddlAgentName,
                        txtStartDate: txtStartDate,
                        txtEndDate: txtEndDate,
                        filtersArray: JSON.stringify(filtersArray)
                    });

                    setFiltersArray(JSON.stringify(filtersArray));
                    setshowLoader1(true)
                    setGetCallLogsCount(true);

                }
            });


        }
        else {
            //  AppUtils.ShowMsg(pnkstore.error.prettyMessage)
        }
        setGetProcessDetails(false);
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
        setAlertMsg('');
    }


    const CustomSearch = () => {

        if (txtStartDate == '' && txtEndDate != '') {
            // alert('WRONG');
            setShowAlert(true);
            setAlertMsg('Select Correct Date Range');
            return false;
        }

        if (txtStartDate != '' && txtEndDate != '') {
            if (txtEndDate < txtStartDate) {
                setShowAlert(true);
                setAlertMsg('Select Correct Date Range');
                return false;
            }
        }
        setSearchObj({
            orgId: userData.getState().orgId,
            processId: ddlProcessId,
            agentName: ddlAgentName,
            txtStartDate: txtStartDate,
            txtEndDate: txtEndDate,
            filtersArray: filtersArray
        });

        setshowLoader1(true);
        setGetCallLogsCount(true);

    }

    const Reload = () => {

        let startDate = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ');
        let endDate = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ');

        settxtStartDate(startDate);
        settxtEndDate(endDate);

        setSearchObj({
            orgId: userData.getState().orgId,
            processId: ddlProcessId,
            agentName: ddlAgentName,
            txtStartDate: startDate,
            txtEndDate: endDate,
            filtersArray: filtersArray
        });

        setshowLoader1(true);
        setGetCallLogsCount(true);

    }


    const HandleCallLogsCountByProcessResponse = (res) => {
        if (res != false && res != 'error') {
            setCallCountData(res.data.calllogscountresponse.message.value);
            setshowLoader1(false);
        }
        else {
            setCallCountData([]);
        }
        setGetCallLogsCount(false);

    }

    return (
        <Box>
            <DashboardCallComp processList={processList} mystate={state} HandleInputs={HandleInputs} HandleCloseAlert={HandleCloseAlert} NavigateTo={NavigateTo} CustomSearch={CustomSearch} Reload={Reload} handleCloseAlert={handleCloseAlert} />
            {((getProcessList === true) ? GetProcessList(pnkstore, userData.getState().profile, userData.getState().username, userData.getState().orgId, userData.getState().token, HandleGetProcessListSuccessResponse) : "")}
            {getProcessDetails === true ? GetProcessDetails(pnkstore, userData.getState().profile, userData.getState().username, ddlProcessId, userData.getState().orgId, userData.getState().token, HandleProcessDetailsResponse) : ''}

            {getCallLogsCount === true ? GetCallLogsCountByProcess(pnkstore, userData.getState().profile, userData.getState().username, searchObj, userData.getState().token, HandleCallLogsCountByProcessResponse) : ''}

            {/* {getCallFiltersCount === true ? GetCallFilterCount(pnkstore, userData.getState().profile, userData.getState().username, searchObj, userData.getState().token, HandleCallFiltersCountResponse) : ''} */}
        </Box>
    )
}