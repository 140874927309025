import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UserDetailsComp } from './userdetails';
import { AddNewUser, GetProcessList, GetUserDetails, UpdateUserDetails } from '../../models/process';
import { pnkstore } from '../../stores/pnk-store';
import { useUserStore } from '../../stores/user-store';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export const UserDetails = () => {
    const userData = useUserStore;
    const [getProcessList, setGetProcessList] = useState(true);
    const [getUserDetails, setGetUserDetails] = useState(false);
    const [processList, setProcessList] = useState([]);
    const [currentProcessId, setCurrentProcessId] = useState('');
    const [currentAgentName, setCurrentAgentName] = useState('');
    const [userList, setUserList] = useState([]);
    const [txtUserName, settxtUserName] = useState('');
    const [txtUserNameErr, settxtUserNameErr] = useState('');
    const [txtUserMobile, settxtUserMobile] = useState('');
    const [txtUserMobileErr, settxtUserMobileErr] = useState('');
    const [txtUserEmail, settxtUserEmail] = useState('');
    const [txtUserEmailErr, settxtUserEmailErr] = useState('');
    const [ddlUserType, setddlUserType] = useState('');
    const [ddlUserTypeErr, setddlUserTypeErr] = useState('');
    const [ddlAssignedProcess, setddlAssignedProcess] = useState([]);
    const [ddlAssignedProcessErr, setddlAssignedProcessErr] = useState('');
    const [ddlUserReportsTo, setddlUserReportsTo] = useState([]);
    const [ddlUserReportsToErr, setddlUserReportsToErr] = useState('');
    const [mode, setMode] = useState('add');
    const [userObj, setUserObj] = useState('');
    const [addUser, setAddUser] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();


    const state = {
        txtUserName: txtUserName,
        txtUserNameErr: txtUserNameErr,
        txtUserMobile: txtUserMobile,
        txtUserMobileErr: txtUserMobileErr,
        txtUserEmail: txtUserEmail,
        txtUserEmailErr: txtUserEmailErr,
        ddlUserType: ddlUserType,
        ddlUserTypeErr: ddlUserTypeErr,
        ddlAssignedProcess: ddlAssignedProcess,
        ddlAssignedProcessErr: ddlAssignedProcessErr,
        ddlUserReportsTo: ddlUserReportsTo,
        ddlUserReportsToErr: ddlUserReportsToErr,
        getProcessList: getProcessList,
        userList: userList,
        mode: mode,
        addUser:addUser,
        editUser:editUser,
    }

    let dataObj = {};



    useEffect(() => {
        console.log(location);
        setCurrentProcessId(location.state.processId);
        setddlAssignedProcess([location.state.processId]);
        setUserList(location.state.userList);

        let user_name = params.user_name;

        if(user_name != undefined && user_name != null && user_name != ''){
            setMode('edit');
            setCurrentAgentName(user_name);
        }

        //  setGetProcessList(true)
    }, []);





    const HandleInputs = (e, name) => {
        let value = e.target.value;


        if (name == 'txtUserName') {
            value = value.toLowerCase();
            settxtUserName(value)
        }
        else if (name == 'txtUserMobile') {
            settxtUserMobile(value)
        }
        else if (name == 'txtUserEmail') {
            value = value.toLowerCase();
            settxtUserEmail(value)
        }
        else if (name == 'ddlUserType') {
            setddlUserType(value)
        }
        else if (name == 'ddlAssignedProcess') {
            setddlAssignedProcess(value)
        }
        else if (name == 'ddlUserReportsTo') {
            setddlUserReportsTo(value)
        }
    }


    const HandleGetProcessListSuccessResponse = (res) => {
        if (res != false && res != 'error') {
            setProcessList(res.data.processlist.rows);
          //  setUserList(res.data.processlist.rows[1].process_members_details);

            if(mode == 'edit'){
                setGetUserDetails(true);
            }
        }
        else {
            setProcessList([]);
        }
        setGetProcessList(false);
    }


    const AddUser = () => {

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (txtUserName.trim() == '') {
            settxtUserNameErr('Enter Username');
            return false;
        }
        else {
            settxtUserNameErr('');
        }
        if (txtUserMobile.trim() == '') {
            settxtUserMobileErr('Enter Mobile');
            return false;
        }
        else {
            settxtUserMobileErr('');
        }

        if (txtUserMobile.trim().length >= 10 && txtUserMobile.trim().length < 15) {
            settxtUserMobileErr('');
        }
        else {
            settxtUserMobileErr('Enter Valid Mobile');
            return false;
        }

        if (txtUserEmail.trim() == '') {
            settxtUserEmailErr('Enter Email');
            return false;
        }
        else {
            settxtUserEmailErr('');
        }

        if (txtUserEmail.match(validRegex)) {
            settxtUserEmailErr('');
        }
        else {
            settxtUserEmailErr('Enter Valid Email');
            return false;
        }

        if (ddlUserType == '') {
            setddlUserTypeErr('Select User Type');
            return false;
        }
        else {
            setddlUserTypeErr('');
        }
        if (ddlAssignedProcess.length == 0) {
            setddlAssignedProcessErr('Select Process');
            return false;
        }
        else {
            setddlAssignedProcessErr('');
        }


        if (ddlUserReportsTo.length == 0) {
            setddlUserReportsToErr('Select Reporting');
            return false;
        }
        else {
            setddlUserReportsToErr('');
        }

        let proc = [];


        if (ddlAssignedProcess.length > 0) {
             ddlAssignedProcess.forEach((item, i) => {
                  let index = processList.findIndex((elem) => elem._id == item);

                  if (index > -1) {
                       let data = {
                            'process_name': processList[index].process_name,
                            'id': processList[index]._id
                       }

                       proc.push(data);
                  }


             })
        }
        else {
             proc = ddlAssignedProcess;
        }

        dataObj = {
            txtUserName: txtUserName,
            txtUserEmail: txtUserEmail,
            txtUserMobile: txtUserMobile,
            ddlAssignedProcess: proc,
            ddlUserReportsTo: ddlUserReportsTo,
            ddlUserType: ddlUserType,
            txtUserFcmKey: '',
            orgId:userData.getState().orgId,
            orgName:userData.getState().orgName,
        }

        console.log(dataObj);

        setUserObj({
            txtUserName: txtUserName,
            txtUserEmail: txtUserEmail,
            txtUserMobile: txtUserMobile,
            ddlAssignedProcess: JSON.stringify(proc),
            ddlUserReportsTo: JSON.stringify(ddlUserReportsTo),
            ddlUserType: ddlUserType,
            txtUserFcmKey: '',
            orgId:userData.getState().orgId,
            orgName:userData.getState().orgName,
        })

        if(mode == 'add'){
            setAddUser(true);
        }
        else{
            setEditUser(true);
        }
    }

    const NavigateTo = (route) => {
        navigate('/' + route, {state:{processId:currentProcessId}});
    }


    const AddNewUserResponse = (res) => {
        if(res != false && res != 'error'){
            setUserObj({});
            NavigateTo('users');
        }
        else{
            console.log(res);
            alert('ppppp');
        }

        setAddUser(false);
        setEditUser(false);
    }


    const GetUserDetailsResponse = (res) => {
        if(res != false && res != 'error'){

            console.log(res);

            let userData = res.data.userdetails.rows[0];

            let assign_process = [];

            if (userData.user_processes.length > 0) {
                userData.user_processes.forEach((item, i) => {
                      assign_process.push(item.id);
                      if (userData.user_processes.length == i + 1) {
                        settxtUserName(userData.user_name);
                        settxtUserEmail(userData.user_email);
                        settxtUserMobile(userData.user_mobile);
                        setddlUserType(userData.user_type);
                        setddlAssignedProcess(assign_process);
                        setddlUserReportsTo(userData.user_reports_to)
                      }
                 });

            }

           

        }
        else{

        }

        setGetUserDetails(false);
    }


    

    return (
        <Box>
            <UserDetailsComp processList={processList} mystate={state} HandleInputs={HandleInputs} NavigateTo={NavigateTo} AddUser={AddUser} />
            {((getProcessList === true) ? GetProcessList(pnkstore, userData.getState().profile, userData.getState().username, userData.getState().orgId, userData.getState().token, HandleGetProcessListSuccessResponse) : "")}
            {((addUser === true) ? AddNewUser(pnkstore, userData.getState().profile, userData.getState().username, userObj, userData.getState().orgId, userData.getState().token, AddNewUserResponse) : "")}
            {((getUserDetails === true) ? GetUserDetails(pnkstore, userData.getState().profile, userData.getState().username, currentAgentName, userData.getState().orgId, userData.getState().token, GetUserDetailsResponse) : "")}
            {((editUser === true) ? UpdateUserDetails(pnkstore, userData.getState().profile, userData.getState().username, currentAgentName, userData.getState().orgId, userObj, userData.getState().token, AddNewUserResponse) : "")}
        </Box>
    )
}