import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UploadDataComp } from './uploaddata';
import { AddManualData, GetPoolDataCount, GetProcessList } from '../../models/process';
import { pnkstore } from '../../stores/pnk-store';
import { useUserStore } from '../../stores/user-store';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export const UploadData = () => {
    const userData = useUserStore;
    const [getProcessList, setGetProcessList] = useState(true);
    const [getPoolDataCount, setGetPoolDataCount] = useState(false);
    const [processList, setProcessList] = useState([]);
    const [poolDataCount, setPoolDataCount] = useState(0);
    const [txtFileName, settxtFileName] = useState('');
    const [fileName, setFileName] = useState('');
    const [ddlProcessId, setddlProcessId] = useState('');
    const [dataObj, setDataObj] = useState({});
    const [poolCountObj, setPoolCountObj] = useState({});
    const [uploadData, setUploadData] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('');

    const navigate = useNavigate();


    const state = {
        ddlProcessId: ddlProcessId,
        getProcessList: getProcessList,
        txtFileName: txtFileName,
        showAlert: showAlert,
        alertMsg: alertMsg,
        alertType: alertType,
        uploadData:uploadData,
        poolDataCount:poolDataCount,
        getPoolDataCount:getPoolDataCount,
    }



    useEffect(() => {
        //  setGetProcessList(true)
    }, []);



    const UploadFile = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        formData.append('destination', 'application/public/uploads/app');

        axios.post('https://www.app.pinclick.com/call/fileupload/calleyo', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                console.log(response.data.fileName);
                setFileName(response.data.fileName);
            })
            .catch(function (error) {
                console.log(error);
                setShowAlert(true);
                setAlertMsg('Please Select Correct File');
                setAlertType('error');
            });
    }


    const UploadData = () => {
        let file_name = fileName;

        if (file_name == undefined || file_name == null || file_name == '') {
            setShowAlert(true);
            setAlertMsg('Please Select A File');
            setAlertType('error');
            return false;
        }

        let process_name = '';
        let process_list = processList;

        let index = process_list.findIndex((item) => item._id == ddlProcessId);

        if (index > -1) {
            process_name = process_list[index].process_name;
        }
        setDataObj({
            org_id: userData.getState().orgId,
            org_name: userData.getState().orgName,
            process_id: ddlProcessId,
            process_name: process_name,
            file_name: file_name
        });
        setUploadData(true);
    }


    const handleCloseAlert = () => {
        setShowAlert(false);
        setAlertMsg('');
    }

    const HandleInputs = (e, name) => {
        let value = e.target.value;

        if (name == 'ddlProcessId') {
            setddlProcessId(value);
            setPoolCountObj({
                orgId: userData.getState().orgId,
                processId: value
            });
            setGetPoolDataCount(true);
        }
        else if (name == 'txtFileName') {


            console.log(e.target.files[0]);

            settxtFileName(value);

            UploadFile(e.target.files[0]);
        }
    }


    const HandleGetProcessListSuccessResponse = (res) => {
        if (res != false && res != 'error') {
            setProcessList(res.data.processlist.rows);
            setddlProcessId(res.data.processlist.rows[1]._id);
            setPoolCountObj({
                orgId: userData.getState().orgId,
                processId: res.data.processlist.rows[1]._id
            })
            setGetPoolDataCount(true);
        }
        else {
            setProcessList([]);
        }
        setGetProcessList(false);
    }

    const ManualDataEntryResponse = (res) => {
        if(res != false && res != 'error'){
            setShowAlert(true);
            setAlertMsg('Data Uploaded Successfully');
            setAlertType('success');
        }
        else{
            setShowAlert(true);
            setAlertMsg('Some Error !!!');
            setAlertType('error');
        }

        setUploadData(false);
        setFileName('');
        settxtFileName('');
    }

    const CallPoolDataCountResponse = (res) => {

        if(res != 'error' && res != false){
            setPoolDataCount(res.data.pooldataresponse.message.value.total_data);
        }
        else{
            setPoolDataCount(0);
        }
        setPoolCountObj({});
        setGetPoolDataCount(false);
    }

    return (
        <Box>
            <UploadDataComp processList={processList} mystate={state} HandleInputs={HandleInputs} UploadData={UploadData} handleCloseAlert={handleCloseAlert} />
            {((getProcessList === true) ? GetProcessList(pnkstore, userData.getState().profile, userData.getState().username, userData.getState().orgId, userData.getState().token, HandleGetProcessListSuccessResponse) : "")}
            {((uploadData === true) ? AddManualData(pnkstore, userData.getState().profile, userData.getState().username, dataObj, userData.getState().token, ManualDataEntryResponse) : '')}
            {((getPoolDataCount === true) ? GetPoolDataCount(pnkstore, userData.getState().profile, userData.getState().username, poolCountObj, userData.getState().token, CallPoolDataCountResponse) : '')}
        </Box>
    )
}