//import { PnkAjax } from '../api/pnk-ajax';
import { PnkAjax } from '../pnk-react/ajax/pnk-ajax';

export function GetProcessList(store, user_profile, user_name, orgId, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Get('call/processes/json/' + user_profile + '/' + user_name + '/' + orgId).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function GetLeadHistoryByStorageId(store, user_profile, user_name, orgId, storageId, custMobile, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Get('call/leadhistorybystorageid/json/' + user_profile + '/' + user_name + '/' + orgId + '/' + storageId + '/' + custMobile).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function GetLeadsFromStorage(store, data, user_profile, user_name, per_set, set_no, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('call/leadsfromstorage/json/' + user_profile + '/' + user_name + '/' + per_set + '/' + set_no).ExtraData(data).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function AddNewUser(store, user_profile, user_name, data, orgId, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('call/users/json/' + user_profile + '/' + user_name + '/' + orgId).ExtraData(data).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function RemoveUser(store, user_profile, user_name, agentName, orgId, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Delete('call/user/json/' + user_profile + '/' + user_name + '/' + agentName + '/' + orgId).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function GetUserDetails(store, user_profile, user_name, agentName, orgId, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Get('call/user/json/' + user_profile + '/' + user_name + '/' + agentName + '/' + orgId).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function UpdateUserDetails(store, user_profile, user_name, agentName, orgId, data, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Put('call/user/json/' + user_profile + '/' + user_name + '/' + agentName + '/' + orgId).ExtraData(data).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function GetCallLogsList(store, searchData, token, SuccessCallBackFunc) {
  console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%');
  console.log(searchData);
  let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('call/calllogslist/json').ExtraData(searchData).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function GetCallLogsCount(store, user_profile, user_name, searchData, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('call/calllogscount/json/' + user_profile + '/' + user_name).ExtraData(searchData).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function GetCallLogsCountByProcess(store, user_profile, user_name, searchData, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('call/callcountsummary/json/' + user_profile + '/' + user_name).ExtraData(searchData).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function GetProcessDetails(store, user_profile, user_name, processId, orgId, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Get('call/process/json/' + processId + '/' + user_profile + '/' + user_name + '/' + orgId).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}


export function GetCallFilterCount(store, user_profile, user_name, searchData, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('call/callfilterscount/json/' + user_profile + '/' + user_name).ExtraData(searchData).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function AddManualData(store, user_profile, user_name, data, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('call/uploaddatatostorage/json/' + user_profile + '/' + user_name).ExtraData(data).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function GetPatchData(store, user_profile, user_name, data, perSet, setNo, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('call/patchsummary/json/' + user_profile + '/' + user_name + '/' + perSet + '/' + setNo).ExtraData(data).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function RechurnAppData(store, user_profile, user_name, data, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('call/rechurndata/json/' + user_profile + '/' + user_name).ExtraData(data).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function GetPoolDataCount(store, user_profile, user_name, searchData, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('call/pooldatacount/json/' + user_profile + '/' + user_name).ExtraData(searchData).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function UpdatePatchLeads(store, user_profile, user_name, data, lead_id, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('call/updateleads/json/' + user_profile + '/' + user_name + '/' + lead_id).ExtraData(data).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function CommentList(store, lead_owner, lead_id, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containecccrId: 'testpnk', size: 'small' };
  return PnkAjax(store).Get('call/leadcomments/json/' + lead_owner + '/' + lead_id).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}

export function UploadComment(store, data, token, SuccessCallBackFunc) {
  let loader = { isLoader: false, containecccrId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('call/leadcomments/json').ExtraData(data).AttachLoader(loader).AddHeader({ 'x-access-token': token }).OnSuccess(SuccessCallBackFunc).Do();
}