export const pnkstore = {
   api: { baseurl: 'https://www.app.pinclick.com' },
//   api: { baseurl: 'https://www.pinclick.com' },
   // api: { baseurl: 'http://localhost:3000' },
     // api: { baseurl: 'http://192.168.0.101:3000/app' },

    user: { name: '', password: '', displayName: '', email: '', role: '' },
    app: { mode: 'prod', token: '', startTime: '', lastLoggedTime: '' },
    error: { errorCode: '', errorType: '', errorMessage: '', prettyMessage: '' },
    ajax: { isFetching: false },
    wetherAuthenticated: false,

    userFcmKeys: '',
    loggedInUserName: '',
    loggedInUserEmail: '',
    loggedInUserCity: '',
    loggedInUserFullName: '',
    loggedInUserMobile: '',
    loggedInUserProfile: '',
    loggedInUserToken: '',
    loggedInUserId: '',
    loggedInUserOrgId: '',
    loggedInUserOrgName: '',
    loggedInUserImage: '',
    allowedLeadStages: [],
    currentCustomerDetails:'',
    userData:'',
    currentProcessDetails:'',

    advisorList: [],
    salesUserList:[],
    propertyList: [],
    cityList: [{ "city_name": "Bangalore" }, { "city_name": "North Bangalore" },
    { "city_name": "Bangalore South" }, { "city_name": "Pune West" },
    { "city_name": "Pune East" }, { "city_name": "Thane" },
    { "city_name": "Mumbai Central" }, { "city_name": "Mumbai Western" }, { "city_name": "KDMC" }],
    budgetList: [{ 'budget_name': 'Below 30 Lakhs', '_id': 'Below 30 Lakhs' },
    { 'budget_name': '30 to 50 Lakhs', '_id': '30 to 50 Lakhs' },
    { 'budget_name': '50 to 80 Lakhs', '_id': '50 to 80 Lakhs' },
    { 'budget_name': '80 Lakhs to 1 Cr', '_id': '80 Lakhs to 1 Cr' },
    { 'budget_name': '1 Cr to 1.2 Cr', '_id': '1 Cr to 1.2 Cr' },
    { 'budget_name': 'Above 1.2 Cr', '_id': 'Above 1.2 Cr' },
    { 'budget_name': 'Above 1.5 Cr', '_id': 'Above 1.5 Cr' },
    { 'budget_name': 'Above 2 Cr', '_id': 'Above 2 Cr' },
    { 'budget_name': 'Above 3 Cr', '_id': 'Above 3 Cr' }],
    profileList:[{'_id':'5c011774e32b5829762d9b6b', 'profile_name':'Sales TL'}, {'_id':'5be04b8a5c87190690301ffa', 'profile_name':'Sales'}],
    purposeList:['Self Use', 'Investment'],
    floorsList:['Floor 1-5', 'Floor 5-10', 'Floor 10-15', 'More Than 15 Floor'],
    unitsList: [],
    possessionList: [],
    currentHeaderTitle: 'Home',
    leadEntryData: [],
    activeTab: '',
    isLocationEnabled: '',
    fromSidebar: false,
    notificationData: [],
    sourceScreen: '',
    meetingTypeFromSummary: '',
    homePageApiCount:0,
    dashboardType:'Sales',
};