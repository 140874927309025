import * as React from 'react';
import { Box, Grid, Button, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import SearchIcon from '@mui/icons-material/Search';
import CachedIcon from '@mui/icons-material/Cached';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const GetTimeFormat = (time) => {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    hours = Math.floor(time / 3600);
    minutes = Math.floor((time - (hours * 3600)) / 60);
    seconds = time - (hours * 3600) - (minutes * 60);

    if (hours == 0) {
        hours = '';
    }
    else {
        hours = hours + ' H ';
    }


    if (minutes == 0) {
        minutes = '';
    }
    else {
        minutes = minutes + ' M ';
    }

    if (seconds == 0) {
        seconds = '';
    }
    else {
        seconds = seconds + ' S ';
    }


    let final_time = hours + minutes + seconds;

    if (final_time == '') {
        final_time = 0
    }

    return final_time;
}

export const DashboardCallComp = (props) => {
    return (
        <Box>
            <Box>
                <Grid container>
                    <Grid md={2}>
                        <Box paddingRight={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Process</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.mystate.ddlProcessId}
                                    label="Process"
                                    onChange={(e) => props.HandleInputs(e, 'ddlProcessId')}
                                >
                                    {
                                        props.processList != undefined && props.processList.length > 0 ?
                                            props.processList.map((item, i) =>
                                                <MenuItem value={item._id}>{item.process_name}</MenuItem>
                                            )
                                            :
                                            null
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    {props.mystate.reportingAgents != undefined && (props.mystate.reportingAgents).length > 0 ?
                        <Grid md={2}>
                            <Box paddingRight={2}>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Agents</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={props.mystate.ddlAgentName}
                                        label="Agenst"
                                        onChange={(e) => props.HandleInputs(e, 'ddlAgentName')}
                                    >
                                        {/* {props.mystate.reportingAgents != undefined && (props.mystate.reportingAgents).length > 0 ? */}
                                        <MenuItem selected value={'All'}>{'All'}</MenuItem>
                                        {/* : ''} */}
                                        {
                                            props.mystate.userList != undefined && props.mystate.userList.length > 0 ?
                                                props.mystate.userList.map((item, i) =>
                                                    // item.role == 'Agent' ?
                                                    props.mystate.loginUserProfile.includes('Super-Admin') ?
                                                        <MenuItem value={item.name}>{item.name}</MenuItem>
                                                        :
                                                        (props.mystate.reportingAgents).includes(item.name) ?
                                                            <MenuItem value={item.name}>{item.name}</MenuItem>
                                                            :
                                                            ''
                                                )
                                                :
                                                null
                                        }
                                    </Select>
                                </FormControl>
                            </Box>

                        </Grid>
                        : ''}

                    <Grid md={3}>
                        <Box marginTop={-1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker onChange={(e) => props.HandleInputs(e, 'txtStartDate')} label="Start Date" value={dayjs(props.mystate.txtStartDate)} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Grid>
                    <Grid md={3}>
                        <Box marginTop={-1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker onChange={(e) => props.HandleInputs(e, 'txtEndDate')} label="End Date" value={dayjs(props.mystate.txtEndDate)} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Grid>

                    <Grid md={2}>
                        <Box textAlign={'end'}>
                            <Button style={{ margin: 10 }} variant="outlined" onClick={() => props.CustomSearch()}><SearchIcon></SearchIcon></Button>
                            <Button style={{ margin: 10 }} variant="outlined" onClick={() => props.Reload()}><CachedIcon></CachedIcon></Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box style={{ marginTop: '20px' }}>
                {
                    props.mystate.getCallLogsCount === false && props.mystate.getProcessList === false && props.mystate.getProcessDetails === false ?
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                key={'SrNo'}
                                                align={'left'}
                                                style={{}}
                                                className='table-head-cell'
                                            >
                                                {'Sr. No'}
                                            </TableCell>
                                            <TableCell
                                                key={'Name'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Name'}
                                            </TableCell>
                                            <TableCell
                                                key={'outgoing'}
                                                align={'left'}
                                                style={{}}
                                                className='table-head-cell'
                                            >
                                                {'Outgoing'}
                                            </TableCell>
                                            <TableCell
                                                key={'Connected'}
                                                align={'left'}
                                                style={{}}
                                                className='table-head-cell'
                                            >
                                                {'Connected'}
                                            </TableCell>
                                            <TableCell
                                                key={'NotConnected'}
                                                align={'left'}
                                                style={{}}
                                                className='table-head-cell'
                                            >
                                                {'Not Connected'}
                                            </TableCell>
                                            <TableCell
                                                key={'incoming'}
                                                align={'left'}
                                                style={{}}
                                                className='table-head-cell'
                                            >
                                                {'Incoming'}
                                            </TableCell>
                                            <TableCell
                                                key={'morethan2'}
                                                align={'left'}
                                                style={{}}
                                                className='table-head-cell'
                                            >
                                                {'2+ Minutes'}
                                            </TableCell>
                                            <TableCell
                                                key={'morethan3'}
                                                align={'left'}
                                                style={{}}
                                                className='table-head-cell'
                                            >
                                                {'3+ Minutes'}
                                            </TableCell>
                                            <TableCell
                                                key={'morethan5'}
                                                align={'left'}
                                                style={{}}
                                                className='table-head-cell'
                                            >
                                                {'5+ Minutes'}
                                            </TableCell>
                                            <TableCell
                                                key={'Total Talktime'}
                                                align={'left'}
                                                style={{}}
                                                className='table-head-cell'
                                            >
                                                {'Total Talktime'}
                                            </TableCell>
                                            <TableCell
                                                key={'Avg Talktime'}
                                                align={'left'}
                                                style={{}}
                                                className='table-head-cell'
                                            >
                                                {'Avg Talktime'}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.mystate.callCountData.length > 0 ?
                                            props.mystate.callCountData.map((item, i) => (
                                                item.user_name != undefined && item.user_name != '' ?
                                                    <TableRow hover tabIndex={-1} key={item._id}>
                                                        <TableCell key={i} align={'left'}>
                                                            {i + 1}
                                                        </TableCell>
                                                        <TableCell key={item.user_name + i} align={'left'}>
                                                            {item.user_name}
                                                        </TableCell>
                                                        <TableCell key={item.outgoing + i} align={'left'}>
                                                            {item.outgoing}
                                                        </TableCell>
                                                        <TableCell key={item.connected + i} align={'left'}>
                                                            {item.connected}
                                                        </TableCell>
                                                        <TableCell key={item.notconnected + i} align={'left'}>
                                                            {item.notconnected}
                                                        </TableCell>
                                                        <TableCell key={item.incoming + i} align={'left'}>
                                                            {item.incoming}
                                                        </TableCell>
                                                        <TableCell key={item.morethentwominutes + i} align={'left'}>
                                                            {item.morethentwominutes}
                                                        </TableCell>
                                                        <TableCell key={item.morethenthreeminutes + i} align={'left'}>
                                                            {item.morethenthreeminutes}
                                                        </TableCell>
                                                        <TableCell key={item.morethenfiveminutes + i} align={'left'}>
                                                            {item.morethenfiveminutes}
                                                        </TableCell>
                                                        <TableCell key={item.total_talktime + i} style={{ fontSize: 12 }} align={'left'}>
                                                            {GetTimeFormat(item.total_talktime)}
                                                        </TableCell>
                                                        <TableCell key={item.total_talktime + i} style={{ fontSize: 12 }} align={'left'}>

                                                            {
                                                                item.total_talktime == 0 || item.connected == 0 ?
                                                                    0
                                                                    :
                                                                    GetTimeFormat(Math.floor(item.total_talktime / item.connected))
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                    :
                                                    ''
                                            ))
                                            :
                                            "No Record Found"
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        :
                        <Box>
                            <Skeleton height={75} />
                            <Skeleton animation="wave" variant="rectangular" height={250} />
                            <Skeleton height={75} />
                        </Box>
                }

            </Box>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={props.mystate.showAlert} autoHideDuration={2000} onClose={() => { props.handleCloseAlert() }}>
                <Alert
                    onClose={() => { props.handleCloseAlert() }}
                    severity={'error'}
                    variant="filled"
                    sx={{ width: '100%' }}

                >
                    {props.mystate.alertMsg}
                </Alert>
            </Snackbar>
        </Box>


    );
}