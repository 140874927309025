import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LeadDetailsComp } from './leaddetails';
import { pnkstore } from '../../stores/pnk-store';
import { useUserStore } from '../../stores/user-store';
import { useParams, useLocation } from 'react-router-dom';
import { CommentList, GetLeadHistoryByLeadId, GetProcessList, UpdatePatchLeads, UploadComment } from '../../models/process';
import { useNavigate } from 'react-router-dom';


export const LeadDetails = (props) => {
    const userData = useUserStore;
    const date = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ');
    const [getProcessList, setGetProcessList] = useState(true);
    const [processListData, setProcessListData] = useState([]);
    const params = useParams();
    const [leadDetails, setLeadDetails] = useState({});
    const location = useLocation();
    const data = location.state;
    const navigate = useNavigate();
    const [txtLeadUserName, settxtLeadUserName] = useState('');
    const [txtLeadUserContactNo, settxtLeadUserContactNo] = useState('');
    const [txtLeadCity, settxtLeadCity] = useState('');
    const [txtLeadProcessName, settxtLeadProcessName] = useState('');
    const [ddlLeadProject, setddlLeadProject] = useState('');
    const [ddlLeadProjectErr, setddlLeadProjectErr] = useState('');
    const [txtLeadOwnerName, settxtLeadOwnerName] = useState('');
    const [txtLeadPatchDateTime, settxtLeadPatchDateTime] = useState('');
    const [ddlLeadStage, setddlLeadStage] = useState('');
    const [ddlLeadStageErr, setddlLeadStageErr] = useState('');

    const [txtSSVDate, settxtSSVDate] = useState('');
    const [txtSSVDateErr, settxtSSVDateErr] = useState('');

    const [txtSVDate, settxtSVDate] = useState('');
    const [txtSVDateErr, settxtSVDateErr] = useState('');

    const [txtDeadDate, settxtDeadDate] = useState('');
    const [txtDeadDateErr, settxtDeadDateErr] = useState('');
    const [txtDeadReason, settxtDeadReason] = useState('');
    const [txtDeadReasonErr, settxtDeadReasonErr] = useState('');

    const [txtLeadDescription, settxtLeadDescription] = useState('');
    const [txtLeadComment, settxtLeadComment] = useState('');
    const [txtLeadCommentErr, settxtLeadCommentErr] = useState('');

    const [leadObj, setLeadObj] = useState({});

    const [updateLead, setUpdateLead] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('error');

    const [screenType, setScreenType] = useState('details');

    const [commentlist, setCommentList] = useState(false);

    const [tlCommentsList, setTlCommentList] = useState([]);

    const [txtTLComment, settxtTLComment] = useState('');
    const [txtTLCommentErr, settxtTLCommentErr] = useState('');
    const [addComment, setAddComment] = useState(false);
    const [commentObj, setCommentObj] = useState({});

    const state = {
        date: date,
        leadDetails: leadDetails,
        processListData: processListData,
        txtLeadUserName: txtLeadUserName,
        txtLeadUserContactNo: txtLeadUserContactNo,
        txtLeadCity: txtLeadCity,
        txtLeadProcessName: txtLeadProcessName,
        ddlLeadProject: ddlLeadProject,
        ddlLeadProjectErr: ddlLeadProjectErr,
        txtLeadOwnerName: txtLeadOwnerName,
        txtLeadPatchDateTime: txtLeadPatchDateTime,
        ddlLeadStage: ddlLeadStage,
        ddlLeadStageErr: ddlLeadStageErr,
        txtSSVDate: txtSSVDate,
        txtSVDate: txtSVDate,
        txtSSVDateErr: txtSSVDateErr,
        txtSVDateErr: txtSVDateErr,
        txtLeadDescription: txtLeadDescription,
        txtLeadComment: txtLeadComment,
        txtLeadCommentErr: txtLeadCommentErr,
        updateLead: updateLead,
        showAlert: showAlert,
        alertMsg: alertMsg,
        alertType: alertType,
        screenType: screenType,
        tlCommentsList: tlCommentsList,
        txtTLComment: txtTLComment,
        txtTLCommentErr: txtTLCommentErr,
        addComment: addComment,
        commentlist: commentlist,
        userData: userData,
        txtDeadDate: txtDeadDate,
        txtDeadDateErr: txtDeadDateErr,
        txtDeadReason: txtDeadReason,
        txtDeadReasonErr: txtDeadReasonErr,
    }

    useEffect(() => {
        console.log(userData.getState().reportingAgents)
        let leadId = params.lead_id;
        console.log(leadId);
        console.log(location);
        setLeadDetails(data.leadDetails)
        settxtLeadUserName(data.leadDetails.cust_name != undefined && data.leadDetails.cust_name != null && data.leadDetails.cust_name != '' ? data.leadDetails.cust_name : 'User');
        settxtLeadUserContactNo(data.leadDetails.cust_mobile != undefined && data.leadDetails.cust_mobile != null && data.leadDetails.cust_mobile != '' ? data.leadDetails.cust_mobile : '')
        settxtLeadCity(data.leadDetails.city != undefined && data.leadDetails.city != null && data.leadDetails.city != '' ? data.leadDetails.city : 'Others')
        settxtLeadProcessName(data.leadDetails.process_name != undefined && data.leadDetails.process_name != null && data.leadDetails.process_name != '' ? data.leadDetails.process_name : 'Others')
        setddlLeadProject(data.leadDetails.process_name != undefined && data.leadDetails.process_name != null && data.leadDetails.process_name != '' ? data.leadDetails.process_name : 'Others')
        settxtLeadOwnerName(data.leadDetails.owner_name != undefined && data.leadDetails.owner_name != null && data.leadDetails.owner_name != '' ? data.leadDetails.owner_name : 'Others')
        settxtLeadPatchDateTime(data.leadDetails.added_on != undefined && data.leadDetails.added_on != null && data.leadDetails.added_on != '' ? data.leadDetails.added_on : 'NA')
        setddlLeadStage(data.leadDetails.stage != undefined && data.leadDetails.stage != null && data.leadDetails.stage != '' ? data.leadDetails.stage : 'NA')
        settxtLeadDescription(data.leadDetails.comments != undefined && data.leadDetails.comments != null && data.leadDetails.comments != '' ? data.leadDetails.comments : 'No Description');

        if (data.leadDetails.stage == 'S-SV') {
            settxtSSVDate(data.leadDetails.stage_date);
        }
        else if (data.leadDetails.stage == 'SV') {
            settxtSVDate(data.leadDetails.stage_date);
        }
        else if (data.leadDetails.stage == 'Dead') {
            settxtDeadDate(data.leadDetails.stage_date);
        }

    }, []);

    const HandleInputs = (e, name) => {
        let value = '';

        if (name == 'txtSSVDate' || name == 'txtSVDate' || name == 'txtDeadDate') {
            value = e.$d;
        }
        else {
            value = e.target.value;
        }

        if (name == 'ddlLeadProject') {
            setddlLeadProject(value);
        }
        else if (name == 'ddlLeadStage') {
            setddlLeadStage(value);
        }
        else if (name == 'txtSSVDate') {
            settxtSSVDate(new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' '));
        }
        else if (name == 'txtSVDate') {
            settxtSVDate(new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' '));
        }
        else if (name == 'txtDeadDate') {
            settxtDeadDate(new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' '));
        }
        else if (name == 'txtDeadReason') {
            settxtDeadReason(value);
        }
        else if (name == 'txtLeadComment') {
            settxtLeadComment(value);
        }
        else if (name == 'txtTLComment') {
            settxtTLComment(value);
        }
    }

    const NavigateTo = (route) => {
        navigate('/' + route, { replace: true });
    }

    const HandleGetProcessListSuccessResponse = (res) => {
        if (res != false && res != 'error') {
            let processListData = res.data.processlist.rows;
            setProcessListData(processListData);
        }
        else {
            setProcessListData([]);
        }
        setGetProcessList(false);
    }

    const UpdateLead = () => {

        let count = 0;

        if (ddlLeadStage == '') {
            setddlLeadStageErr('Select Stage');
            count = count + 1;
        }
        else {
            setddlLeadStageErr('');
        }

        if (ddlLeadStage == 'S-SV') {
            if (txtSSVDate.trim() === '') {
                settxtSSVDateErr('Select S-SV Date');
                count = count + 1;
            }
            else {
                settxtSSVDateErr('');
            }

        }
        if (ddlLeadStage == 'SV') {
            if (txtSVDate.trim() === '') {
                settxtSVDateErr('Select SV Date');
                count = count + 1;
            }
            else {
                settxtSVDateErr('');
            }

        }
        if (ddlLeadStage == 'Dead') {
            if (txtDeadDate.trim() === '') {
                settxtDeadDateErr('Select Dead Date');
                count = count + 1;
            }
            else {
                settxtDeadDateErr('');
            }
            if (txtDeadReason.trim() === '') {
                settxtDeadReasonErr('Enter Dead Reason');
                count = count + 1;
            }
            else {
                settxtDeadReasonErr('');
            }

        }

        if (txtLeadComment.trim() === '') {
            settxtLeadCommentErr('Enter Comment');
            count = count + 1;
        }
        else {
            settxtLeadCommentErr('');
        }

        if (count == 0) {
            let leadObj = {
                ddlLeadStage: ddlLeadStage,
                txtSSVDate: txtSSVDate,
                txtSVDate: txtSVDate,
                txtDeadDate: txtDeadDate,
                txtDeadReason: txtDeadReason,
                ddlLeadProject: ddlLeadProject,
                ddlLeadOwnerName: txtLeadOwnerName,
                txtLeadComment: txtLeadComment,
                txtLeadProcessName: txtLeadProcessName,
                txtLeadProcessId: leadDetails.process_id,
                txtLeadId: params.lead_id
            };

            setLeadObj(leadObj);
            setUpdateLead(true);
        }
    }

    const HandleLeadEditResponse = (res) => {

        console.log(res);

        if (res != false && res != 'error') {
            let msg = res.data.leadupdateresponse.message.value;
            let status = res.data.leadupdateresponse.value;
            if (status == 'SUCCESS') {
                setAlertType('success');
                setShowAlert(true);
                setAlertMsg(msg);
                setTimeout(function () {
                    NavigateTo('patch-list');
                }, 2000);
            }
            else {
                setAlertType('error');
                setShowAlert(true);
                setAlertMsg(msg);
            }

        }
        else {
            setShowAlert(false);
            setAlertType('error');
            setAlertMsg('Some Error !!!');
        }
        setUpdateLead(false);
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
    }

    const ToggleScreen = (type) => {
        setScreenType(type);
        if (type == 'comments') {
            setCommentList(true);
        }
    }

    const CommentsListResponse = (res) => {
        if (res != 'error') {
            let comnts = res.data.leadcommentsresponse.message.value.tl_comments;
            if (comnts.length > 0) {
                comnts.sort((a, b) => new Date(b.comment_date) - new Date(a.comment_date));
            }
            setTlCommentList(comnts);
        }
        else {
            setTlCommentList([]);
        }
        setCommentList(false);
    }

    const AddNewComment = () => {
        if (txtTLComment.trim() === '') {
            settxtTLCommentErr('Enter Comment');
            return false;
        }
        else {
            settxtTLCommentErr('');
        }
        let commentObj = {
            id: leadDetails._id,
            owner: leadDetails.owner_name,
            follow_date: '',
            comments: txtTLComment
        };

        setCommentObj(commentObj);
        setAddComment(true);
    }

    const UploadCommentResponse = (res) => {
        if (res != 'error' && res != false) {
            settxtTLComment('');
            setAlertType('success');
            setShowAlert(true);
            setAlertMsg('Comment Added');
            setCommentList(true);
        }
        else {
            setAlertType('error');
            setShowAlert(true);
            setAlertMsg('Some Error !!!');
        }
        setAddComment(false);
    }

    return (
        <Box>
            <LeadDetailsComp mystate={state} NavigateTo={NavigateTo} HandleInputs={HandleInputs} UpdateLead={UpdateLead} handleCloseAlert={handleCloseAlert} ToggleScreen={ToggleScreen} AddNewComment={AddNewComment} />
            {((getProcessList === true) ? GetProcessList(pnkstore, userData.getState().profile, userData.getState().username, userData.getState().orgId, userData.getState().token, HandleGetProcessListSuccessResponse) : "")}
            {((updateLead === true) ? UpdatePatchLeads(pnkstore, userData.getState().profile, userData.getState().username, leadObj, leadDetails._id, userData.getState().token, HandleLeadEditResponse) : '')}
            {((commentlist === true) ? CommentList(pnkstore, leadDetails.owner_name, leadDetails._id, userData.getState().token, CommentsListResponse) : '')}
            {((addComment === true) ? UploadComment(pnkstore, commentObj, userData.getState().token, UploadCommentResponse) : '')}
        </Box>
    )
}