import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Grid, Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';






export const LeadsComp = (props) => {


    return (
        <Box>
            <Box>
                <Grid container>
                    <Grid md={3}>
                        <Box>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Process</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.mystate.ddlProcessId}
                                    label="Process"
                                    onChange={(e) => props.HandleInputs(e, 'ddlProcessId')}
                                >
                                    {
                                        props.processList != undefined && props.processList.length > 0 ?
                                            props.processList.map((item, i) =>
                                                <MenuItem value={item._id}>{item.process_name}</MenuItem>
                                            )
                                            :
                                            null
                                    }
                                </Select>
                            </FormControl>
                        </Box>

                    </Grid>
                </Grid>
            </Box>
            <Box style={{ marginTop: '20px' }}>
                {
                    props.mystate.getLeadList == false && props.mystate.getProcessList == false ?
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 'calc(100vh - 250px)' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                key={'SrNo'}
                                                align={'left'}
                                                style={{ minWidth: 70 }}
                                                className='table-head-cell'
                                            >
                                                {'SrNo'}
                                            </TableCell>
                                            <TableCell
                                                key={'Name'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Name'}
                                            </TableCell>
                                            <TableCell
                                                key={'Contact'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Contact'}
                                            </TableCell>
                                            <TableCell
                                                key={'Property'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Property'}
                                            </TableCell>
                                            <TableCell
                                                key={'Call Status'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Call Status'}
                                            </TableCell>
                                            <TableCell
                                                key={'Last Added On'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Last Added On'}
                                            </TableCell>
                                            <TableCell
                                                key={'Details'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {''}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            console.log(props.mystate.leadList)
                                        }
                                        {props.mystate.leadList != undefined && props.mystate.leadList.length > 0 ?
                                            props.mystate.leadList.map((item, i) => (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={item._id}>
                                                    <TableCell key={props.mystate.totalRecords + i + 1} align={'left'}>
                                                        {((props.mystate.setNo - 1) * props.mystate.perSet) + i + 1}
                                                    </TableCell>
                                                    <TableCell key={item.cust_name + i} align={'left'}>
                                                        {item.cust_name != undefined && item.cust_name != '' ? item.cust_name : 'User'}
                                                    </TableCell>
                                                    <TableCell key={item.cust_mobile + i} align={'left'}>
                                                        <span>{item.cust_mobile} <br></br> {'(' + item.cust_email + ')'}</span>
                                                    </TableCell>
                                                    <TableCell key={item.property + i} align={'left'}>
                                                        {item.property != undefined && item.property != '' ? item.property : 'NA'}
                                                    </TableCell>
                                                    <TableCell key={item.cust_call_status + i} align={'left'}>
                                                        {item.cust_call_status}
                                                    </TableCell>
                                                    <TableCell key={item.cust_added_on + i} align={'left'}>
                                                        {item.cust_added_on}
                                                    </TableCell>
                                                    <TableCell key={item.cust_added_on + i} align={'left'}>
                                                        <Button size="small" onClick={() => props.GetLeadHistory(item._id, item.cust_mobile)}>Details</Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            : ''
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[50, 100, 200, 300]}
                                component="div"
                                count={props.mystate.totalRecords}
                                rowsPerPage={props.mystate.perSet}
                                // page={props.mystate.setNo == 1 ? 1 + '-' + props.mystate.perSet : props.mystate.perSet * (props.mystate.setNo - 1) + '-' + props.mystate.perSet * (props.mystate.setNo) }
                                page={props.mystate.setNo - 1}
                                onPageChange={(e) => props.handleChangePage(e)}
                                //onPageChange={handleChangePage}
                                onRowsPerPageChange={(e) => props.handleChangeRowsPerPage(e)}
                            />
                        </Paper>
                        :

                        <Box>
                            <Skeleton height={75} />
                            <Skeleton animation="wave" variant="rectangular" height={250} />
                            <Skeleton height={75} />
                        </Box>
                }

            </Box>
        </Box>


    );
}