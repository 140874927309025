import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GetLeadsFromStorage, GetProcessDetails, GetProcessList, RechurnAppData } from '../../models/process';
import { pnkstore } from '../../stores/pnk-store';
import { useUserStore } from '../../stores/user-store';
import { useNavigate } from 'react-router-dom';
import { DataRechurnComp } from './datarechurn';

export const DataRechurn = () => {
    const userData = useUserStore;
    const [getProcessList, setGetProcessList] = useState(true);
    const [getProcessDetails, setGetProcessDetails] = useState(false);
    const [getLeadList, setGetLeadList] = useState(false);
    const [processList, setProcessList] = useState([]);
    const [filterListData, setFilterListData] = useState([]);
    const [ddlProcessId, setddlProcessId] = useState('');
    const [ddlFilterData, setFilterData] = useState([]);
    const [txtStartDate, settxtStartDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' '));
    const [txtEndDate, settxtEndDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' '));
    const [rechurnObj, setRechurnObj] = useState({});
    const [rechurnData, setRechurnData] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('');

    const navigate = useNavigate();


    const state = {
        ddlProcessId: ddlProcessId,
        ddlFilterData: ddlFilterData,
        getLeadList: getLeadList,
        getProcessList: getProcessList,
        filterListData: filterListData,
        txtStartDate: txtStartDate,
        txtEndDate: txtEndDate,
        showAlert: showAlert,
        alertMsg: alertMsg,
        alertType: alertType,
        rechurnData:rechurnData
    }



    useEffect(() => {
        //  setGetProcessList(true)
    }, []);


    const handleCloseAlert = () => {
        setShowAlert(false);
        setAlertMsg('');
    }


    const HandleInputs = (e, name) => {
        let value = '';

        if (name == 'txtStartDate' || name == 'txtEndDate') {
            value = e.$d;
        }
        else {
            value = e.target.value;
        }

        if (name == 'ddlProcessId') {
            setddlProcessId(value);
            setGetProcessDetails(true);
        }
        else if (name == 'ddlFilterData') {
            setFilterData(value);
            console.log(value);
        }
        else if (name == 'txtStartDate') {
            let date = new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ')
            settxtStartDate(date);
        }

        else if (name == 'txtEndDate') {
            let date = new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ')
            settxtEndDate(date);
        }
    }


    const HandleGetProcessListSuccessResponse = (res) => {
        if (res != false && res != 'error') {
            setProcessList(res.data.processlist.rows);
        }
        else {
            setProcessList([]);
        }
        setGetProcessList(false);
    }


    const ProcessDetailsResponse = (res) => {


        if (res != false) {
            let data = res.data.processdetails.rows;
            if (data.length > 0) {
                let newArray = [];
                data.forEach((element, i) => {

                    if (element.field_as_filter == 'Yes') {

                        let options = element.options_arr.split(',');
                        let options_arr = [];

                        if (options.length > 0) {
                            options.forEach((elem, e) => {
                                options_arr.push({ 'option_name': elem });
                            })
                        }

                        let newData = {
                            'filter_name': element.field_name,
                            'filter_options': options_arr
                        }



                        newArray.push(newData);
                    }

                });

                console.log(newArray);
                setFilterListData(newArray);
            }
            else {
                setFilterListData([]);
            }


        }
        else {
            setFilterListData([]);
        }

        setGetProcessDetails(false);
    }

    const RechurnData = () => {

        var date1 = new Date(txtStartDate);
        var date2 = new Date(txtEndDate);
        var diff = date2.getTime() - date1.getTime();
        diff = diff / (1000 * 60 * 60 * 24 * 30);

        if (ddlProcessId == '') {
            setAlertMsg('Please Select One Process');
            setAlertType('error');
            setShowAlert(true);
            return false;
        }
        if (txtStartDate > txtEndDate) {
            setAlertMsg('Please Select Correct Date Range');
            setAlertType('error');
            setShowAlert(true);
            return false;
        }
        if (diff > 3) {
            setAlertMsg('Date Range Cannot Be More Than 3 Months');
            setAlertType('error');
            setShowAlert(true);
            return false;
        }

        let filterArr = filterListData;
        filterArr[0].selected_item = ddlFilterData;


        setRechurnObj({
            'ddlProcessName': ddlProcessId,
            'txtStartDate': txtStartDate,
            'txtEndDate': txtEndDate,
            'ddlOrgId': userData.getState().orgId,
            'orgName': userData.getState().orgName,
            'filterListData': JSON.stringify(filterArr)
        });

        console.log(filterArr);


        setRechurnData(true);


    }


   const RechurnDataResponse = (res) =>{
    
        if(res != 'error' && res != false){
            setAlertMsg(res.data.rechurndataresponse.message.value);
            setAlertType('success');
            setShowAlert(true);
            setddlProcessId('');
            setFilterListData([]);
            setFilterData([]);
        }
        else{
            setAlertMsg(pnkstore.error.prettyMessage);
            setAlertType('error');
            setShowAlert(true);
        }
        setRechurnData(false);
   }

    return (
        <Box>
            <DataRechurnComp processList={processList} mystate={state} HandleInputs={HandleInputs} RechurnData={RechurnData} handleCloseAlert={handleCloseAlert} />
            {((getProcessList === true) ? GetProcessList(pnkstore, userData.getState().profile, userData.getState().username, userData.getState().orgId, userData.getState().token, HandleGetProcessListSuccessResponse) : "")}
            {((getProcessDetails === true) ? GetProcessDetails(pnkstore, userData.getState().profile, userData.getState().username, ddlProcessId, userData.getState().orgId, userData.getState().token, ProcessDetailsResponse) : '')}
            {((rechurnData === true) ? RechurnAppData(pnkstore, userData.getState().profile, userData.getState().username, rechurnObj, userData.getState().token, RechurnDataResponse) : '')}
        </Box>
    )
}