import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Grid, Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import SearchIcon from '@mui/icons-material/Search';
import CachedIcon from '@mui/icons-material/Cached';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export const LogsComp = (props) => {
    return (
        <Box>
            <Box>
                <Grid container>
                    <Grid md={5}>
                        <Grid container>
                            <Grid md={4}>
                                <Box paddingRight={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Process</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={props.mystate.ddlProcessId}
                                            label="Process"
                                            onChange={(e) => props.HandleInputs(e, 'ddlProcessId')}
                                        >
                                            {
                                                props.processList != undefined && props.processList.length > 0 ?
                                                    props.processList.map((item, i) =>
                                                        <MenuItem value={item._id}>{item.process_name}</MenuItem>
                                                    )
                                                    :
                                                    null
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>

                            </Grid>
                            {props.mystate.reportingAgents != undefined && (props.mystate.reportingAgents).length > 0 ?
                                <Grid md={4}>
                                    <Box paddingRight={2}>
                                      
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Agents</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={props.mystate.ddlAgentName}
                                                label="Agenst"
                                                onChange={(e) => props.HandleInputs(e, 'ddlAgentName')}
                                            >
                                                {/* {props.mystate.reportingAgents != undefined && (props.mystate.reportingAgents).length > 0 ? */}
                                                <MenuItem selected value={'All'}>{'All'}</MenuItem>
                                                {/* : ''} */}
                                                {
                                                    props.mystate.userList != undefined && props.mystate.userList.length > 0 ?
                                                        props.mystate.userList.map((item, i) =>
                                                            // item.role == 'Agent' ?
                                                            props.mystate.loginUserProfile.includes('Super-Admin') ?
                                                                <MenuItem value={item.name}>{item.name}</MenuItem>
                                                                :
                                                                (props.mystate.reportingAgents).includes(item.name) ?
                                                                    <MenuItem value={item.name}>{item.name}</MenuItem>
                                                                    :
                                                                    ''
                                                        )
                                                        :
                                                        null
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
        
                                </Grid>
                                : ''}
                            <Grid md={4}>
                                <Box paddingRight={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Duration</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={props.mystate.ddlDuration}
                                            label="Agenst"
                                            onChange={(e) => props.HandleInputs(e, 'ddlDuration')}
                                        >
                                            <MenuItem selected value={'All'}>{'All'}</MenuItem>
                                            <MenuItem value="0">Below 30 Sec</MenuItem>
                                            <MenuItem value="30">Above 30 Sec</MenuItem>
                                            <MenuItem value="60">Above 1 Min</MenuItem>
                                            <MenuItem value="120">Above 2 Min</MenuItem>
                                            <MenuItem value="180">Above 3 Min</MenuItem>
                                            <MenuItem value="300">Above 5 Min</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid md={7}>
                        <Grid container>
                            <Grid md={4}>
                                <Box marginTop={-1} paddingRight={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker onChange={(e) => props.HandleInputs(e, 'txtStartDate')} label="Start Date" value={dayjs(props.mystate.txtStartDate)} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                            <Grid md={4}>
                                <Box marginTop={-1} paddingRight={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker onChange={(e) => props.HandleInputs(e, 'txtEndDate')} label="End Date" value={dayjs(props.mystate.txtEndDate)} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                            <Grid md={4}>
                                <Box textAlign={'end'} paddingRight={2}>
                                    <Button style={{ margin: 10 }} variant="outlined" onClick={() => props.CustomSearch()}><SearchIcon></SearchIcon></Button>
                                    <Button style={{ margin: 10 }} variant="outlined" onClick={() => props.Reload()}><CachedIcon></CachedIcon></Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>




                </Grid>
            </Box>
            <Box style={{ marginTop: '20px' }}>
                {
                    props.mystate.getCallLogList == false && props.mystate.getProcessList == false ?
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 'calc(100vh - 250px)' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                key={'SrNo'}
                                                align={'left'}
                                                style={{ minWidth: 70 }}
                                                className='table-head-cell'
                                            >
                                                {'SrNo'}
                                            </TableCell>
                                            <TableCell
                                                key={'Caller Name'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Caller Name'}
                                            </TableCell>
                                            <TableCell
                                                key={'Client'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Client'}
                                            </TableCell>
                                            <TableCell
                                                key={'Call Time'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Call Time'}
                                            </TableCell>
                                            <TableCell
                                                key={'Duration'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Duration'}
                                            </TableCell>
                                            <TableCell
                                                key={'Type'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Type'}
                                            </TableCell>
                                         
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            console.log(props.mystate.logsList)
                                        }
                                        {props.mystate.logsList != undefined && props.mystate.logsList.length > 0 ?
                                            props.mystate.logsList.map((item, i) => (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={item._id}>
                                                    <TableCell key={props.mystate.totalRecords + i + 1} align={'left'}>
                                                        {((props.mystate.setNo - 1) * props.mystate.perSet) + i + 1}
                                                    </TableCell>
                                                    <TableCell key={item.called_by + i} align={'left'}>
                                                        {item.called_by != undefined && item.called_by != '' ? item.called_by : 'User'}
                                                    </TableCell>
                                                    <TableCell key={item.user_name + i} align={'left'}>
                                                        <span>{item.user_name != undefined ? item.user_name : 'User'} <br></br> {'(' + item.user_mobile != undefined ? item.user_mobile : 'NA' + ')'}</span>
                                                    </TableCell>
                                                    <TableCell key={item.call_time + i} align={'left'}>
                                                        {item.call_time != undefined && item.call_time != '' ? item.call_time : 'NA'}
                                                    </TableCell>
                                                    <TableCell key={item.call_duration + i} align={'left'}>
                                                        {item.call_duration + ' sec'}
                                                    </TableCell>
                                                    <TableCell key={item.call_type + i} align={'left'}>
                                                        {item.call_type}
                                                    </TableCell>
                                                  
                                                </TableRow>
                                            ))
                                            : 'No Record Found'
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[50, 100, 200, 300]}
                                component="div"
                                count={props.mystate.totalRecords}
                                rowsPerPage={props.mystate.perSet}
                                // page={props.mystate.setNo == 1 ? 1 + '-' + props.mystate.perSet : props.mystate.perSet * (props.mystate.setNo - 1) + '-' + props.mystate.perSet * (props.mystate.setNo) }
                                page={props.mystate.setNo - 1}
                                onPageChange={(e) => props.handleChangePage(e)}
                                //onPageChange={handleChangePage}
                                onRowsPerPageChange={(e) => props.handleChangeRowsPerPage(e)}
                            />
                        </Paper>
                        :

                        <Box>
                            <Skeleton height={75} />
                            <Skeleton animation="wave" variant="rectangular" height={250} />
                            <Skeleton height={75} />
                        </Box>
                }

            </Box>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={props.mystate.showAlert} autoHideDuration={2000} onClose={() => { props.handleCloseAlert() }}>
                <Alert
                    onClose={() => { props.handleCloseAlert() }}
                    severity={'error'}
                    variant="filled"
                    sx={{ width: '100%' }}

                >
                    {props.mystate.alertMsg}
                </Alert>
            </Snackbar>
        </Box>


    );
}