import React, { Component } from 'react';


export const PnkLoader = (props) => {
    return (
        <div class="loader_react">
            {/* <div style={{
            /*boxShadow: '0 0 11px 2px',*
            // opacity: '0.1',
            // padding: '0',
            // borderRadius: '6px',
            backgroundColor: 'rgba(204, 204, 204, 0.1)',
            position: 'fixed',
            // textAlign: 'center',
            zIndex: '99998',
            left: '42%',
            top: '35%',
            width: '100%',
            height: '100%'

        }}>
             */}
            {/* <div style={{
                opacity: '1 ',
                position: 'fixed',
                zIndex: '99999',
                left: '42%',
                top: '40%'
            }}> */}

            {
                props.size === 'small' ?
                    <img height="200" width="200" src={require("../../assets/images/loader.png")} alt="" /> :
                    props.size === 'large' ?
                        <img height="300" width="300" src={require("../../assets/images/loader.png")} alt="" /> :

                        <img height="150" width="150" src={require("../../assets/images/loader.png")} alt="" />

            }

        </div>
        // </div>

    );
}