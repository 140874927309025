import React, { useState } from 'react';
import { Box } from "@mui/material"
import LoginComp from "./login";
import { LoginByNumber } from '../../models/user';
import { pnkstore } from '../../stores/pnk-store';
import { useUserStore } from '../../stores/user-store';
import AppUtils from '../../apputils';
import { useNavigate } from 'react-router-dom';


const Login = () => {

  const [txtNumber, settxtNumber] = useState('');
  const [txtNumberErr, settxtNumberErr] = useState('');
  const [txtPassword, settxtPassword] = useState('');
  const [txtPasswordErr, settxtPasswordErr] = useState('');
  const [login, setLogin] = useState(false);
  const [loginData, setLoginData] = useState({});
  const [alertMsg, setAlertMsg] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const userData = useUserStore;
  const navigate = useNavigate();

  if (!window.location.href.includes('login')) {
    window.history.pushState('login', 'Login', 'login');
    window.history.replaceState('login', 'Login', 'login');
  }

  const state = {
    txtNumber: txtNumber,
    txtNumberErr: txtNumberErr,
    txtPassword: txtPassword,
    txtPasswordErr: txtPasswordErr,
    login: login,
    alertMsg: alertMsg,
    showAlert: showAlert,
    alertType: alertType
  }

  const HandleInputs = (e, name) => {
    let value = e.target.value;

    if (name == 'txtNumber') {
      settxtNumber(value);
    }
    else if (name == 'txtPassword') {
      settxtPassword(value);
    }
  }

  const SignIn = () => {
    if (txtNumber.trim() == '') {
      settxtNumberErr('Invalid Username');
      return false;
    }
    else {
      settxtNumberErr('');
    }
    if (txtPassword.trim() == '') {
      settxtPasswordErr('Invalid Password');
      return false;
    }
    else {
      settxtPasswordErr('');
    }
    setLoginData({
      txtNumber: txtNumber,
      txtPassword: txtPassword
    })
    // console.log(loginData);
    setLogin(true);
  }

  const handleCloseAlert = () => {
    setShowAlert(false);
    setAlertMsg('');
    setAlertType('');
  }

  const HandleAddUserSuccessResponse = (res) => {
    if (res !== 'error') {
      if (res.data.PinakaResponse.ServerStatus.value === 'SUCCESS') {
        if (res.data.loginresp.rows.msg !== undefined && res.data.loginresp.rows.msg !== '') {
          setShowAlert(true);
          setAlertMsg(res.data.loginresp.rows.msg);
          setAlertType('error');
        }
        else {
          setShowAlert(true);
          setAlertMsg('Successfully Logged In');
          setAlertType('success');

          let userDetails = res.data.loginresp.rows[0];
          let token = res.data.loginresp.token;
          userData.setState({ userId: userDetails._id, name: userDetails.user_name, username: userDetails.user_name, email: userDetails.user_email, mobile: userDetails.user_mobile, profile: userDetails.user_type, reportingAgents: userDetails.user_reporting_agents, orgId: userDetails.org_details[0]._id, orgName: userDetails.org_details[0].org_name, token: token, isLogin: true });
          // navigate('/summary', { replace: true });
          window.location.href = '/summary';

        }
      }
      else {

      }
    }
    else {
      AppUtils.ShowMsg(pnkstore.error.prettyMessage)
    }

    setLogin(false);
  }

  return (
    <Box>
      <LoginComp
        state={state}
        login={login}
        SignIn={SignIn}
        HandleInputs={HandleInputs}
        handleCloseAlert={handleCloseAlert}
      />
      {console.log(loginData)}
      {((login === true) ? LoginByNumber(pnkstore, loginData, HandleAddUserSuccessResponse) : "")}
    </Box>
  );
}


export default Login;