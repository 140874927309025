import * as React from 'react';
import { Box, Grid, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import SearchIcon from '@mui/icons-material/Search';
import CachedIcon from '@mui/icons-material/Cached';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export const PatchDashboardComp = (props) => {
    return (
        <Box>
            <Box>
                <Grid container>
                    <Grid md={2}>
                        <Box paddingRight={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Process</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.mystate.ddlProcessId}
                                    label="Process"
                                    onChange={(e) => props.HandleInputs(e, 'ddlProcessId')}
                                >
                                    {
                                        props.processList != undefined && props.processList.length > 0 ?
                                            props.processList.map((item, i) =>
                                                <MenuItem value={item.process_name}>{item.process_name}</MenuItem>
                                            )
                                            :
                                            null
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    {props.mystate.reportingAgents != undefined && (props.mystate.reportingAgents).length > 0 ?
                        <Grid md={2}>
                            <Box paddingRight={2}>
                              
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Agents</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={props.mystate.ddlAgentName}
                                        label="Agenst"
                                        onChange={(e) => props.HandleInputs(e, 'ddlAgentName')}
                                    >
                                        {/* {props.mystate.reportingAgents != undefined && (props.mystate.reportingAgents).length > 0 ? */}
                                        <MenuItem selected value={'All'}>{'All'}</MenuItem>
                                        {/* : ''} */}
                                        {
                                            props.mystate.userList != undefined && props.mystate.userList.length > 0 ?
                                                props.mystate.userList.map((item, i) =>
                                                    // item.role == 'Agent' ?
                                                    props.mystate.loginUserProfile.includes('Super-Admin') ?
                                                        <MenuItem value={item.name}>{item.name}</MenuItem>
                                                        :
                                                        (props.mystate.reportingAgents).includes(item.name) ?
                                                            <MenuItem value={item.name}>{item.name}</MenuItem>
                                                            :
                                                            ''
                                                )
                                                :
                                                null
                                        }
                                    </Select>
                                </FormControl>
                            </Box>

                        </Grid>
                        : ''}

                    <Grid md={3}>
                        <Box marginTop={-1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker onChange={(e) => props.HandleInputs(e, 'txtStartDate')} label="Start Date" value={dayjs(props.mystate.txtStartDate)} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Grid>
                    <Grid md={3}>
                        <Box marginTop={-1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker onChange={(e) => props.HandleInputs(e, 'txtEndDate')} label="End Date" value={dayjs(props.mystate.txtEndDate)} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Grid>

                    <Grid md={2}>
                        <Box textAlign={'end'}>
                            <Button style={{ margin: 10 }} variant="outlined" onClick={() => props.CustomSearch()}><SearchIcon></SearchIcon></Button>
                            <Button style={{ margin: 10 }} variant="outlined" onClick={() => props.Reload()}><CachedIcon></CachedIcon></Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box style={{ marginTop: '20px' }}>
                {
                    props.mystate.getProcessList == false && props.mystate.getPatchApi == false ?
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                key={'SrNo'}
                                                align={'left'}
                                                style={{ minWidth: 70 }}
                                                className='table-head-cell'
                                            >
                                                {'Sr. No'}
                                            </TableCell>
                                            <TableCell
                                                key={'Name'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Name'}
                                            </TableCell>
                                            <TableCell
                                                key={'count'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Total Patch'}
                                            </TableCell>
                                            <TableCell
                                                key={'Progress'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'In Progress'}
                                            </TableCell>
                                            <TableCell
                                                key={'s-sv'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'S-SV'}
                                            </TableCell>
                                            <TableCell
                                                key={'sv'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'SV'}
                                            </TableCell>
                                            <TableCell
                                                key={'dead'}
                                                align={'left'}
                                                style={{ minWidth: 170 }}
                                                className='table-head-cell'
                                            >
                                                {'Dead'}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.patchData.length > 0 ?
                                            props.patchData.map((item, i) => (
                                                <TableRow hover tabIndex={-1} key={item._id}>
                                                    <TableCell key={i} align={'left'}>
                                                        {i + 1}
                                                    </TableCell>
                                                    <TableCell key={item.cust_name + i} align={'left'}>
                                                        {item.user_name}
                                                    </TableCell>
                                                    <TableCell key={item.cust_mobile + i} align={'left'}>
                                                        {item.patch_count}
                                                    </TableCell>
                                                    <TableCell key={item.property + i} align={'left'}>
                                                        {item.progress}
                                                    </TableCell>
                                                    <TableCell key={item.s_sv + i} align={'left'}>
                                                        {item.s_sv}
                                                    </TableCell>
                                                    <TableCell key={item.sv + i} align={'left'}>
                                                        {item.sv}
                                                    </TableCell>
                                                    <TableCell key={item.dead + i} align={'left'}>
                                                        {item.dead}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            : 'No Record Found'
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        :

                        <Box>
                            <Skeleton height={75} />
                            <Skeleton animation="wave" variant="rectangular" height={250} />
                            <Skeleton height={75} />
                        </Box>
                }

            </Box>


            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={props.mystate.showAlert} autoHideDuration={2000} onClose={() => { props.handleCloseAlert() }}>
                <Alert
                    onClose={() => { props.handleCloseAlert() }}
                    severity={'error'}
                    variant="filled"
                    sx={{ width: '100%' }}

                >
                    {props.mystate.alertMsg}
                </Alert>
            </Snackbar>
        </Box>


    );
}