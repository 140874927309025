import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../../stores/user-store';
import CallIcon from '@mui/icons-material/Call';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import Summarize from '@mui/icons-material/Summarize';
import SettingsPhone from '@mui/icons-material/SettingsPhone';
import Grading from '@mui/icons-material/Grading';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subMenu, setSubMenu] = React.useState(false);
  const [subMenuText, setSubMenuText] = React.useState('');
  const userData = useUserStore;
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateTo = (route, type) => {
    if (type == 'menu') {
      setSubMenuText('');
      if (route == 'summary' && subMenu == false) {
        setSubMenu(true);
      }
      else {
        setSubMenu(false);
        if (open == false) {
          navigate('/' + route, { replace: true });
        }
        else {
          if (route != 'summary') {
            navigate('/' + route, { replace: true });
          }
        }

      }
    }
    else if (type == 'sub') {
      setSubMenuText(route);
      navigate('/' + route, { replace: true });
    }

  }

  const LogOut = () => {
    userData.setState({ userId: '', name: '', username: '', email: '', mobile: '', profile: '', orgId: '', orgName: '', token: '', isLogin: false });
    navigate('/login');
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar className='calleyo-bar' position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Calleyo
          </Typography>
          <div className='header-icon'>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem>{userData.getState().username}</MenuItem>
              <MenuItem onClick={LogOut}>Log Out</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem title='Dashboard' key={'Dashboard'} disablePadding sx={{ display: 'block' }} onClick={() => navigateTo('summary', 'menu')}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {<DashboardIcon />}
              </ListItemIcon>
              <ListItemText primary={'Dashboard'} sx={{ opacity: open ? 1 : 0 }} />

            </ListItemButton>


          </ListItem>
         
          {
            // subMenu == true && open == true ?
            subMenu == true ?
              open == true ?
                <Box className="sub-menu">
                  <Typography onClick={() => navigateTo('summary', 'sub')} className='sub-menu-item' style={{ color: subMenuText == 'summary' ? '#042740' : 'darkgray' }}>Summary</Typography>
                  <Typography onClick={() => navigateTo('call-summary', 'sub')} className='sub-menu-item' style={{ color: subMenuText == 'call-summary' ? '#042740' : 'darkgray' }}>Call Summary</Typography>
                  <Typography onClick={() => navigateTo('dashboard-patch', 'sub')} className='sub-menu-item' style={{ color: subMenuText == 'dashboard-patch' ? '#042740' : 'darkgray' }}>Patch Report</Typography>
                </Box>
                :
                <Box paddingX={3} paddingY={1}>
                  {<Summarize style={{ cursor: 'pointer', fontSize:18 }} onClick={() => navigateTo('summary', 'sub')} />}
                  <br />
                  {<SettingsPhone style={{ cursor: 'pointer', fontSize:18 }} onClick={() => navigateTo('call-summary', 'sub')} />}
                  <br />
                  {<Grading style={{ cursor: 'pointer', fontSize:18 }} onClick={() => navigateTo('dashboard-patch', 'sub')} />}
                </Box>
              :
              ''
          }
          <ListItem title='Patch List' key={'Patch List'} disablePadding sx={{ display: 'block' }} onClick={() => navigateTo('patch-list', 'menu')}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {<LeaderboardIcon />}
              </ListItemIcon>
              <ListItemText primary={'Patch List'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>


          {(userData.getState().profile).includes('Admin') ?
            <ListItem title='Call Logs' key={'Call Logs'} disablePadding sx={{ display: 'block' }} onClick={() => navigateTo('call-logs', 'menu')}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {<CallIcon />}
                </ListItemIcon>
                <ListItemText primary={'Call Logs'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            : ''
          }

          {(userData.getState().profile).includes('Admin') ?
            <ListItem title='Lead List' key={'Lead List'} disablePadding sx={{ display: 'block' }} onClick={() => navigateTo('leads', 'menu')}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {<LeaderboardIcon />}
                </ListItemIcon>
                <ListItemText primary={'Lead List'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            : ''
          }



          {(userData.getState().profile).includes('Admin') ?
            <ListItem title='Upload Data' key={'Upload Data'} disablePadding sx={{ display: 'block' }} onClick={() => navigateTo('uploaddata', 'menu')}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {<CloudUploadIcon />}
                </ListItemIcon>
                <ListItemText primary={'Upload Data'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            : ''}

          {(userData.getState().profile).includes('Admin') ?
            <ListItem title='Data Rechurn' key={'Data Rechurn'} disablePadding sx={{ display: 'block' }} onClick={() => navigateTo('data-rechurn', 'menu')}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {<ShuffleIcon />}
                </ListItemIcon>
                <ListItemText primary={'Data Rechurn'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            : ''}

          {(userData.getState().profile).includes('Admin') ?
            <ListItem title='Users' key={'Users'} disablePadding sx={{ display: 'block' }} onClick={() => navigateTo('users', 'menu')}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {<GroupAddIcon />}
                </ListItemIcon>
                <ListItemText primary={'Users'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            : ''}
        </List>
        <Divider />

      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
      </Box>
    </Box>
  );
}